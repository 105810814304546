import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Grid,
  IconButton,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
  Checkbox,
  DialogContent,
  Dialog,
  CircularProgress,
  createFilterOptions,
} from "@mui/material";
import FetchModels from "./FetchModels.css";
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { api } from "../../../contexts/JWTContext";
import { Virtuoso } from "react-virtuoso";
import ReviewCard from "../../../pages/UserPages/EmotionAi/Components/ReviewCard";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../../components/Loaders/Loader";

const useStyles = makeStyles((theme) => ({
  tooltipstyle: {
    fontSize: "large",
    paddingBottom: "3px",
    color: "#8D9298",
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const FetchModel = ({
  title,
  open,
  handleCloseReviewModel,
  location,
  topicId,
  allLocations,
  selectedLocation,
  setSelectedLocation,
  getsentimentalLocation,
  requestLoading,
  filterObj,
  setReviewsData,
  reviewsData,
  sentimentValueForReview,
  setSentimentValueForReview,
  sentimentValue,
  startDate,
  endDate,
  language,
  emoationAIDetailsLoading,
  setEmotionAIDetailsLoading,
  emotionAIDetailsData,
  setEmotionAIDetailsData,
  varianceType,
  setSelectedLocationId,
  selectedLocationId,
  thirdPartySourceId,
  reviewsLoading,
  setReviewsLoading,
  ratingTeXTKeywords,
  ratingBreakdownValue,
  handleDisplayNames,
  apiCalls,
  isLoadingMoreData,
  setIsLoadingMoreData,
  keywordsSentiments,
  pageNumber,
  setPageNumber,
  apiCallsFrom,
  platformLocations,
  platformGroups,
  fromPlatform,
  selectedGroupId,
  locations,
  selectedGroups,
  allReviewSource,
  reviewsTitle,
  typeParam,
  nextStartDate,
  nextEndDate,
  selectedTopicName,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [deletePost, setDeletePost] = useState(null);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [selectAll, setSelectAll] = useState(false);
  const [locationIds, setLocationIds] = useState("");
  const filter = createFilterOptions();
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  useEffect(() => {
    if (apiCallsFrom === "new") {
      getEmotionAIDetails(startDate, endDate);
      // getKeywordsCloudDetail(selectedLocationId, startDate, endDate);
    } else if (apiCallsFrom === "old") {
      getAllReviewsData(selectedLocationId);
    } else {
      getKeywordsCloudDetail(selectedLocationId, startDate, endDate);
    }
  }, [open]);

  const getEmotionAIDetails = async (startDate, endDate) => {
    setReviewsLoading(true);

    // const result =
    //   selectedLocationId === "" &&
    //   varianceType !== "" &&
    //   varianceType !== undefined
    //     ? varianceType
    //     : "";

    const queryParams = {
      pageNumber: pageNumber + 1,
      limit: 10,
      locationIds: fromPlatform
        ? platformLocations?.length > 0
          ? platformLocations?.toString()
          : ""
        : selectedLocationId !== undefined
        ? selectedLocationId
        : "",
      groupIds: fromPlatform
        ? platformGroups?.length > 0
          ? platformGroups.toString()
          : ""
        : selectedGroupId !== undefined
        ? selectedGroupId
        : "",
      startDate: startDate.toISOString(),
      topic: selectedTopicName !== null ? selectedTopicName : "",
      endDate: endDate.toISOString(),
      type:
        varianceType !== "" && varianceType !== undefined ? varianceType : "",
      sentimentAnalysis:
        sentimentValue !== undefined ? sentimentValue?.value : "",
      thirdPartyReviewSourcesId:
        thirdPartySourceId !== undefined ? thirdPartySourceId : "",
      ratingText: ratingTeXTKeywords !== undefined ? ratingTeXTKeywords : "",
      ratingValue:
        ratingBreakdownValue !== undefined ? ratingBreakdownValue : "",
      languageCode: language?.value !== undefined ? language?.value : "",
      nextStartDate:
        varianceType !== "" &&
        varianceType === "previous" &&
        nextStartDate !== null
          ? nextStartDate
          : "",
      nextEndDate:
        varianceType !== "" &&
        varianceType === "previous" &&
        nextEndDate !== null
          ? nextEndDate
          : "",
    };

    try {
      const response = await api.get("emotionAi/emotionAIDetails", {
        params: queryParams,
      });
      if (response.status === 200) {
        setReviewsData(response?.data?.data);
        setReviewsLoading(false);
        setPageNumber(pageNumber + 1);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setReviewsLoading(false);
    }
  };

  const fetchMoreData = async () => {
    if (reviewsData?.results?.length < reviewsData?.count) {
      const queryParams = {
        pageNumber: pageNumber + 1,
        limit: 10,
        locationIds: fromPlatform
          ? platformLocations?.length > 0
            ? platformLocations?.toString()
            : ""
          : selectedLocationId !== undefined
          ? selectedLocationId
          : "",
        groupIds: fromPlatform
          ? platformGroups?.length > 0
            ? platformGroups.toString()
            : ""
          : selectedGroupId !== undefined
          ? selectedGroupId
          : "",
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        topic: selectedTopicName !== null ? selectedTopicName : "",
        type:
          varianceType !== "" && varianceType !== undefined ? varianceType : "",
        sentimentAnalysis:
          sentimentValue !== undefined ? sentimentValue?.value : "",
        thirdPartyReviewSourcesId:
          thirdPartySourceId !== undefined ? thirdPartySourceId : "",
        ratingText: ratingTeXTKeywords !== undefined ? ratingTeXTKeywords : "",
        ratingValue:
          ratingBreakdownValue !== undefined ? ratingBreakdownValue : "",
        languageCode: language?.value !== undefined ? language?.value : "",
        nextStartDate: nextStartDate !== null ? nextStartDate : "",
        nextEndDate:
          varianceType !== "" &&
          varianceType === "previous" &&
          nextEndDate !== null
            ? nextEndDate
            : "",
      };
      try {
        const res = await api.get("emotionAi/emotionAIDetails", {
          params: queryParams,
        });
        if (res?.status === 200) {
          setReviewsData((prevData) => ({
            ...prevData,
            results: [...prevData?.results, ...res?.data?.data?.results], // Append new data to existing data
          }));
          setPageNumber(pageNumber + 1);
        } else {
          setReviewsData([]);
        }
      } catch (error) {
        setReviewsData([]);
      }
    }
  };

  const getKeywordsCloudDetail = async (
    selectedLocationId,
    startDate,
    endDate
  ) => {
    // let locationIds = [];
    // if (locations?.length > 0) {
    //   locations?.map((item) => {
    //     locationIds.push(item?.id);
    //   });
    // }

    let formatedStartDate = "";
    let formatedEndDate = "";
    if (startDate !== null) {
      let startDateNew = dayjs(startDate).startOf("day");
      formatedStartDate = startDateNew.toISOString();
    }
    if (endDate !== null) {
      let endDateNew = dayjs(endDate);
      formatedEndDate = endDateNew.toISOString();
    }

    const locationIds = location.map((item) => item.id).join(",");
    const groupIds = selectedGroups.map((item) => item.id).join(",");
    // let groupId = [];
    // if (selectedGroups?.length > 0) {
    //   selectedGroups?.map((item) => {
    //     groupId.push(item?.id);
    //   });
    // }

    const queryParams = {
      pageNumber: pageNumber + 1,
      limit: 10,
      locationIds: fromPlatform
        ? platformLocations?.length > 0
          ? platformLocations?.toString()
          : ""
        : locationIds !== undefined
        ? locationIds
        : "",
      groupIds,
      startDate: formatedStartDate,
      endDate: formatedEndDate,
      sentimentAnalysis: keywordsSentiments !== "" ? keywordsSentiments : "",
      ratingText: ratingTeXTKeywords !== undefined ? ratingTeXTKeywords : "",
      languageCode: language?.value !== undefined ? language?.value : "",
    };

    try {
      setReviewsLoading(true);
      const response = await api.get("emotionAi/keywordCloudDetails", {
        params: queryParams,
      });
      if (response.status === 200) {
        setReviewsData(response?.data?.data);
        setReviewsLoading(false);
        setPageNumber(pageNumber + 1);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setReviewsLoading(false);
    }
  };

  const fetchMoreDataKeywords = async () => {
    if (reviewsData?.results?.length < reviewsData?.count) {
      const locationIds = location.map((item) => item.id).join(",");
      const groupIds = selectedGroups.map((item) => item.id).join(",");

      let formatedStartDate = "";
      let formatedEndDate = "";
      if (startDate !== null) {
        let startDateNew = dayjs(startDate).startOf("day");
        formatedStartDate = startDateNew.toISOString();
      }
      if (endDate !== null) {
        let endDateNew = dayjs(endDate);
        formatedEndDate = endDateNew.toISOString();
      }
      const queryParams = {
        pageNumber: pageNumber + 1,
        limit: 10,
        locationIds: fromPlatform
          ? platformLocations?.length > 0
            ? platformLocations?.toString()
            : ""
          : locationIds !== undefined
          ? locationIds
          : "",
        groupIds,
        startDate: formatedStartDate,
        endDate: formatedEndDate,

        sentimentAnalysis: keywordsSentiments !== "" ? keywordsSentiments : "",
        ratingText: ratingTeXTKeywords !== undefined ? ratingTeXTKeywords : "",
        languageCode: language?.value !== undefined ? language?.value : "",
      };
      try {
        const res = await api.get("emotionAi/keywordCloudDetails", {
          params: queryParams,
        });
        if (res.status === 200) {
          setReviewsData((prevData) => ({
            ...prevData,
            results: [...prevData?.results, ...res.data.data.results],
          }));
          setPageNumber(pageNumber + 1);
        } else {
          setReviewsData([]);
        }
      } catch (error) {
        setReviewsData([]);
      }
    }
  };

  const getAllReviewsData = async (location) => {
    let today = new Date();
    const queryParams = {
      pageNumber: pageNumber + 1,
      limit: 10,
      locationIds: selectedLocationId !== undefined ? selectedLocationId : "",
      groupIds: selectedGroupId !== undefined ? selectedGroupId : "",
      startDate: "",
      endDate: today,
      type:
        varianceType !== "" && varianceType !== undefined ? varianceType : "",
      sentimentAnalysis:
        sentimentValue !== undefined ? sentimentValue?.value : "",
      thirdPartyReviewSourcesId:
        thirdPartySourceId !== undefined ? thirdPartySourceId : "",
      ratingText: ratingTeXTKeywords !== undefined ? ratingTeXTKeywords : "",
      ratingValue:
        ratingBreakdownValue !== undefined ? ratingBreakdownValue : "",
      languageCode: language?.value !== undefined ? language?.value : "",
    };
    setReviewsLoading(true);
    try {
      const response = await api.get(`emotionAi/emotionAIDetails`, {
        params: queryParams,
      });
      if (response.status === 200) {
        setReviewsData(response?.data?.data);
        setReviewsLoading(false);
        setPageNumber(pageNumber + 1);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setReviewsLoading(false);
    }
  };
  const fetchMoreAllReviewsData = async () => {
    if (reviewsData?.results?.length < reviewsData?.count) {
      let today = new Date();
      const queryParams = {
        pageNumber: pageNumber + 1,
        limit: 10,
        locationIds: selectedLocationId !== undefined ? selectedLocationId : "",
        groupIds: selectedGroupId !== undefined ? selectedGroupId : "",
        startDate: "",
        endDate: today,
        type:
          varianceType !== "" && varianceType !== undefined ? varianceType : "",
        sentimentAnalysis:
          sentimentValue !== undefined ? sentimentValue?.value : "",
        thirdPartyReviewSourcesId:
          thirdPartySourceId !== undefined ? thirdPartySourceId : "",
        ratingText: ratingTeXTKeywords !== undefined ? ratingTeXTKeywords : "",
        ratingValue:
          ratingBreakdownValue !== undefined ? ratingBreakdownValue : "",
        languageCode: language?.value !== undefined ? language?.value : "",
      };

      try {
        // setReviewsLoading(true);
        const res = await api.get(`emotionAi/emotionAIDetails`, {
          params: queryParams,
        });
        if (res.status === 200) {
          // setReviewsData((prev) => {});
          let data;
          let tempReviews = reviewsData?.results;
          data = { ...res.data.data };
          data["results"] = [...tempReviews, ...res.data.data?.results];
          setReviewsData(data);
          setPageNumber(pageNumber + 1);
          //  setReviewsLoading(false);
        } else {
          setReviewsData([]);
          // setLoading(false);
        }
      } catch (error) {
        setReviewsData([]);
        //  setReviewsLoading(false);
      }
    }
  };

  return (
    <>
      <div>
        <Dialog
          //  sx={{ "& .MuiDialog-paper": { width: "80%", height: "400px" } }}
          open={open}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px",
                borderRadius: "8px",
              },
            },
          }}
          fullWidth
        >
          <DialogTitle
            sx={{
              height: "56px",
              lineHeight: "28px",
              fontWeight: 700,
              fontSize: "20px",
              color: "#1b2430",
            }}
          >
            <Grid container>
              <Grid item xs={10} sm={10} md={11.5} lg={11.5}>
                <Typography
                  sx={{
                    lineHeight: "28px",
                    fontWeight: 700,
                    fontSize: "20px",
                    color: "#1b2430",
                  }}
                >
                  {title +
                    `${
                      reviewsTitle !== null && reviewsTitle !== undefined
                        ? " " +
                          t("for_key") +
                          " " +
                          reviewsTitle.charAt(0).toUpperCase() +
                          reviewsTitle.slice(1)
                        : ""
                    }`}
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={0.5} lg={0.5}>
                <IconButton
                  autoFocus
                  onClick={() => {
                    handleCloseReviewModel();

                    // setSentimentValueForReview(null);
                    // setTopicId(null);
                  }}
                  className="delete_button"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider variant="middle" />

          <DialogContentText sx={{ height: "410px" }}>
            {reviewsLoading ? (
              <Grid
                container
                sx={{
                  marginTop: "150px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader />
              </Grid>
            ) : reviewsData?.results?.length > 0 ? (
              <Virtuoso
                style={{
                  width: "100%",
                  height: "350px",
                }}
                data={reviewsData?.results}
                endReached={
                  apiCallsFrom === "new"
                    ? fetchMoreData
                    : apiCallsFrom === "old"
                    ? fetchMoreAllReviewsData
                    : fetchMoreDataKeywords
                }
                itemContent={(index) => (
                  <div className="scrollLeft">
                    <div>
                      <ReviewCard
                        item={reviewsData?.results[index]}
                        location={location}
                        ratingBreakdownValue={ratingBreakdownValue}
                        handleDisplayNames={handleDisplayNames}
                        sentimentValue={sentimentValue}
                        apiCallsFrom={apiCallsFrom}
                        allReviewSource={allReviewSource}
                      />
                    </div>
                  </div>
                )}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20%",
                }}
              >
                <Typography>{"No data found"}</Typography>
              </Box>
            )}
          </DialogContentText>
        </Dialog>
      </div>
    </>
  );
};

export default FetchModel;
