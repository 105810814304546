import React from "react";
import {
  Grid,
  IconButton,
  Divider,
  Typography,
  DialogTitle,
  DialogContentText,
  Box,
  Dialog,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Virtuoso } from "react-virtuoso";
import ReviewCard from "./ReviewCard";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  tooltipstyle: {
    fontSize: "large",
    paddingBottom: "3px",
    color: "#8D9298",
  },
  titleText: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#1B2430",
  },
  headingText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#495059",
  },
  headingTextGrid: {
    marginBottom: "10px",
  },
}));

const FetchModel = ({
  open,
  title,
  handleCloseReviewModel,
  reviewsData,
  allReviewSource,
  setReviewsValue,
  reviewValue,
  handleChangeReviewsInPrompt,
  handleDisplayNames,
  setSelectedReviewData,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div>
        <Dialog
          //  sx={{ "& .MuiDialog-paper": { width: "80%", height: "400px" } }}
          open={open}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px",
                borderRadius: "8px",
              },
            },
          }}
          fullWidth
        >
          <DialogTitle>
            <Box sx={{ display: "flex" }}>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <Typography className={classes.titleText}>{title}</Typography>
              </Grid>

              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                sx={{ justifyContent: "flex-end", textAlign: "end" }}
              >
                <IconButton
                  autoFocus
                  onClick={handleCloseReviewModel}
                  className="delete_button"
                  sx={{ paddingTop: "0px" }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Box>
            <Grid className={classes.headingTextGrid}>
              <Typography className={classes.headingText}>
                {t("text_prompt_subheading")}
              </Typography>
            </Grid>
          </DialogTitle>
          <Divider variant="middle" />

          <DialogContentText sx={{ height: "410px" }}>
            {reviewsData?.length > 0 ? (
              <Virtuoso
                style={{
                  width: "100%",
                  height: "350px",
                }}
                data={reviewsData}
                itemContent={(index) => (
                  <div className="scrollLeft">
                    <div style={{ marginBottom: "12px", marginTop: "12px" }}>
                      <ReviewCard
                        item={reviewsData[index]}
                        allReviewSource={allReviewSource}
                        setReviewsValue={setReviewsValue}
                        handleCloseReviewModel={handleCloseReviewModel}
                        reviewValue={reviewValue}
                        handleChangeReviewsInPrompt={
                          handleChangeReviewsInPrompt
                        }
                        handleDisplayNames={handleDisplayNames}
                        setSelectedReviewData={setSelectedReviewData}
                      />
                    </div>
                  </div>
                )}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20%",
                }}
              >
                <Typography>{"No data found"}</Typography>
              </Box>
            )}
          </DialogContentText>
        </Dialog>
      </div>
    </>
  );
};

export default FetchModel;
