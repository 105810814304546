import React, { useState } from "react";
import moment from "moment";

import { Grid, Typography, Box } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Rating from "@mui/material/Rating";
import { useStyles } from "../../SentimentalAnalysis/styles/styles";
import { useEffect } from "react";
import useDateFormat from "../../../../hooks/useDateFormat";

const ReviewCard = ({
  item,
  handleDisplayNames,
  apiCallsFrom,
  allReviewSource,
}) => {
  const classes = useStyles();
  const [reviewSource, setReviewSource] = useState(null);
  const baseURL = process.env.REACT_APP_BASE_URL;

  const {formatDate} = useDateFormat()

  useEffect(() => {
    if (apiCallsFrom === "old" || apiCallsFrom === "keyword") {
      const itemSource = allReviewSource?.results?.find(
        (itemOne) => itemOne?.id === item?.thirdPartyReviewSourcesId
      );
      setReviewSource(itemSource);
    }
  }, [item, apiCallsFrom, allReviewSource]);
  return (
    <>
      <Grid container>
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.reviewModalStyle}
          >
            <Box className={classes.reviewCardBox}>
              <Box>
                <img
                  alt=""
                  src={`${baseURL}/${
                    apiCallsFrom === "keyword"
                      ? reviewSource !== null
                        ? reviewSource?.image
                        : ""
                      : item?.thirdPartyReviewSource?.image
                  }`}
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                />
              </Box>
              <Box className={classes.reviewCardBoxTwo}>
                <Box className={classes.reviewCardBoxThree}>
                  <Box>
                    <Typography className={classes.reviewerTitleTypo}>
                      {item?.reviewerTitle}
                    </Typography>

                    <Typography sx={{}}>
                      {" "}
                      {formatDate(item?.date)}
                    </Typography>
                    <Typography>
                      <Rating
                        readOnly
                        name="read-only"
                        value={item.ratingValue}
                        onChange={(event, newValue) => {
                          // setValue(newValue);
                        }}
                      />
                    </Typography>
                  </Box>
                  <Box className={classes.reviewCardBox}>
                    <LocationOnOutlinedIcon className={classes.locationIcon} />
                    <Typography className={classes.locationTypo}>
                      {handleDisplayNames(item?.locationId)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box mt={3}>
              <Typography
                sx={{
                  color:
                    item?.sentimentAnalysis === null
                      ? "#1B2430"
                      : item?.sentimentAnalysis === "Negative"
                      ? "#f4596e"
                      : item?.sentimentAnalysis === "Positive"
                      ? "#3dba5c"
                      : "#f7ae0b",
                }}
              >
                {item?.ratingText}
              </Typography>

              <Typography className={classes.negativeText}></Typography>
            </Box>
          </Grid>
        </>
      </Grid>
    </>
  );
};

export default ReviewCard;
