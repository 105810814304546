import React, { useState, useEffect } from "react";
import EditModal from "../../Modal";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Paper,
  Button,
  Dialog,
  Tooltip,
  tooltipClasses,
} from "@mui/material";

import Divider from "@mui/material/Divider";
import Truncate from "react-truncate";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import Instagram from "../../../../../../assets/images/socialAccountImgs/Instagram.svg";
// import Twitter from "../../../../../../assets/images/Twitter.svg";
import Twitter from "../../../../../../assets/images/twitterX.svg";
import Youtube from "../../../../../../assets/images/Youtube.svg";
import Pinterest from "../../../../../../assets/images/Pinterest.svg";
import Fb from "../../../../../../assets/images/fbicon.svg";
import { useStyles } from "../../../Styles/style";
import Facebook from "../../../../../../assets/images/facebookround.svg";
import VimeoIcon from "../../../../../../assets/images/socialAccountImgs/Vimeo.svg";
import Foursquare from "../../../../../../assets/images/socialAccountImgs/Foursquare.svg";
import Xing from "../../../../../../assets/images/xingLogo.svg";
import Google from "../../../../../../assets/images/googleround.svg";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "../../../../../../assets/images/LinkedIn.svg";

import NotFound from "../../../../../../components/NotFound/NotFound";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Socialaccountdetails({
  locationDetailsData,
  location,
  getLocationDetails,
  singleLocation,
  googleConnect,
  getLocationById,
  locationCategory,
  setSingleLocationLoading,
  lodgingCategories,
  categoryLoading,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  centerData,
  displayMapError,
  setIndexValue,
  getLocationDetailsListing,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [facebookConnect, setFacebookConnected] = useState(false);
  const [displayFacebookIcon, setDisplayFacebookIcon] = useState(false);
  const [socialLinksAccounts, setSocialLinksAccounts] = useState([]);

  useEffect(() => {
    if (locationDetailsData !== null) {
      let arr = [];
      if (locationDetailsData?.result?.uberall?.socialProfiles?.length > 0) {
        locationDetailsData?.result?.uberall?.socialProfiles?.map((item) => {
          if (item?.type === "YOUTUBE") {
            let obj = {
              type: item?.type,
              url: item?.url,
              logo: Youtube,
            };
            arr.push(obj);
          } else if (item?.type === "FACEBOOK") {
            let obj = {
              type: item?.type,
              url: item?.url,
              logo: Facebook,
            };
            arr.push(obj);
          } else if (item?.type === "INSTAGRAM") {
            let obj = {
              type: item?.type,
              url: item?.url,
              logo: Instagram,
            };
            arr.push(obj);
          } else if (item?.type === "TWITTER") {
            let obj = {
              type: item?.type,
              url: item?.url,
              logo: Twitter,
            };
            arr.push(obj);
          } else if (item?.type === "PINTEREST") {
            let obj = {
              type: item?.type,
              url: item?.url,
              logo: Pinterest,
            };
            arr.push(obj);
          } else if (item?.type === "FOURSQUARE") {
            let obj = {
              type: item?.type,
              url: item?.url,
              logo: Foursquare,
            };
            arr.push(obj);
          } else if (item?.type === "LINKEDIN") {
            let obj = {
              type: item?.type,
              url: item?.url,
              logo: LinkedInIcon,
            };
            arr.push(obj);
          } else if (item?.type === "XING") {
            let obj = {
              type: item?.type,
              url: item?.url,
              logo: Xing,
            };
            arr.push(obj);
          }
        });
        setSocialLinksAccounts(arr);
      } else {
        setSocialLinksAccounts([]);
      }
    }
  }, [locationDetailsData]);

  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  useEffect(() => {
    if (location?.enableFacebook === true) {
      setDisplayFacebookIcon(true);
    }
    location?.socialLink?.map((item) => {
      /*  if (item?.type==="google" && item?.isConnected===true) {
        setGoogleConnected(true);
      } */
      if (item?.type === "facebook") {
        setFacebookConnected(true);
      }
    });
  }, []);

  return (
    <>
      {locationDetailsData !== null ? (
        <>
          {openDefaultModel && (
            <Dialog
              maxWidth
              maxHeight
              open={openDefaultModel}
              //  onClose={handleCloseTemplateMenu}
            >
              <EditModal
                modalIndex={8}
                location={location}
                locationDetailsData={locationDetailsData}
                onCancel={handleCloseTemplateMenu}
                getLocationDetails={getLocationDetails}
                setSingleLocationLoading={setSingleLocationLoading}
                getLocationById={getLocationById}
                singleLocation={singleLocation}
                locationCategory={locationCategory}
                lodgingCategories={lodgingCategories}
                centerData={centerData}
                displayMapError={displayMapError}
                setIndexValue={setIndexValue}
                getLocationDetailsListing={getLocationDetailsListing}
              />
            </Dialog>
          )}
          <Card
            variant="outlined"
            sx={{
              bgcolor: "#ffff",
              borderRadius: "8px",
              height: "auto",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container sx={{ padding: "16px" }}>
                  <Grid item xs={8} sm={8} md={9} lg={9}>
                    <Typography className={classes.titleName}>
                      {t("Social_Accounts")}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={3}>
                    {editPermission && viewPermission ? (
                      <Button fullWidth onClick={handleOpenTemplateMenu}>
                        <Typography sx={{ fontWeight: 600 }}>
                          {t("Edit")}
                        </Typography>
                      </Button>
                    ) : (
                      <BootstrapTooltip title={t("authorized_access")}>
                        <span>
                          <Button
                            disabled
                            fullWidth
                            sx={{ cursor: "pointer" }}
                            onClick={handleOpenTemplateMenu}
                          >
                            <Typography sx={{ fontWeight: 600 }}>
                              {t("Edit")}
                            </Typography>
                          </Button>
                        </span>
                      </BootstrapTooltip>
                    )}
                  </Grid>
                </Grid>
                {socialLinksAccounts?.length > 0 &&
                  socialLinksAccounts?.map((item, index) => {
                    return (
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Grid container className={classes.gridContainers}>
                            <Grid item xs={3} sm={3} md={3} lg={3}>
                              <img alt="" src={item?.logo} />
                            </Grid>
                            <Grid
                              item
                              xs={9}
                              sm={9}
                              md={9}
                              lg={9}
                              textAlign="center"
                            >
                              <Truncate
                                lines={1}
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  color: "#06BDFF",
                                }}
                                ellipsis={<span>...</span>}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    color: "#06BDFF",
                                  }}
                                  // className={classes.headNameDescAttr}
                                >
                                  {item.url !== "" && item?.url !== null
                                    ? item?.url
                                    : "--"}
                                </Typography>
                              </Truncate>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Divider />
                      </>
                    );
                  })}
              </Grid>
            </Grid>
          </Card>
        </>
      ) : (
        <NotFound text={t("No_Data_Found")} />
      )}
    </>
  );
}
export default Socialaccountdetails;
