import React, { useEffect, useRef, useState } from "react";
import { Chart } from "chart.js";
import {
  Typography,
  Grid,
  Autocomplete,
  TextField,
  Tooltip as MuiToolTip,
  tooltipClasses,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "chartjs-adapter-moment";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  LabelList,
  Tooltip,
} from "recharts";
import dayjs from "dayjs";
import { CircularProgress, Box } from "@mui/material";
import NoDataFound from "./NoDataFound/NoDataFoundOnlineReputation";
import { WaveSpinner } from "../../../../../components/Loaders/wavaloader";
import { styled } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useStyles } from "../../Style/style";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CircleIcon from "@mui/icons-material/Circle";
import useDateFormat from "../../../../../hooks/useDateFormat";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <MuiToolTip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const CustomLabel = ({ x, y, width, height, value, data, onClick }) => {
  const handleClick = (event) => {
    event.stopPropagation();
    onClick(data);
  };

  if (!value) return null;

  return (
    <text
      x={x + width / 2}
      y={y + height / 2}
      textAnchor="middle"
      dominantBaseline="middle"
      fill="#fff"
      style={{ cursor: "pointer" }}
      onClick={handleClick}
    >
      {value}
    </text>
  );
};
const EmotionAiTopics = ({
  startDate,
  endDate,
  reputationLoading,
  downloadFormat,
  viewPermission,
  setDownloadFormat,
  featuresObj,
  setFeaturesObj,
  topicsData,
  topicsDataLoading,
  tooltipContent,
  prevTooltipContent,
  handleOpenReviewModel,
  setRatingBreakdownValue,
  setSelectedTopicName,
}) => {
  const chartRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation();
  const firstGridRef = useRef(null);
  const [chartHeight, setChartHeight] = useState(0);
  const [chartWidth, setChartWidth] = useState(0);
  const [tooltipData, setTooltipData] = useState(null);

  const [downloadValue, setDownloadValue] = useState(null);
  const companyData = JSON.parse(localStorage.getItem("company"));

  let timer = 0;

  const { formatDate } = useDateFormat();

  useEffect(() => {
    if (downloadFormat === "") {
      setDownloadValue(null);
    }
  }, [downloadFormat]);

  useEffect(() => {
    if (topicsData?.length > 0) {
      if (firstGridRef.current) {
        if (chartHeight === 0) {
          setChartHeight(firstGridRef.current.clientHeight);
          setChartWidth(firstGridRef.current.clientWidth);
        } else if (firstGridRef.current.clientHeight < chartHeight) {
          setChartHeight(firstGridRef.current.clientHeight);
          setChartWidth(firstGridRef.current.clientWidth);
        }
      }
    }
  }, [topicsData, topicsDataLoading]);

  const downloadOptions = [
    { id: 1, value: "download", title: t("downlaod_pdf") },
    { id: 1, value: "email", title: t("email_report") },
  ];
  const handleReputationDownloadChange = (event, newValue) => {
    setDownloadFormat(newValue?.value);
    setDownloadValue(newValue);
    let tempFeatureObj = featuresObj;
    Object.keys(tempFeatureObj)?.map((itemTwo) => {
      if (itemTwo === "emotionAITopics") {
        tempFeatureObj[itemTwo] = true;
      } else {
        tempFeatureObj[itemTwo] = false;
      }
    });
    setFeaturesObj(tempFeatureObj);
  };
  const handleCalculateDifference = (currentValue, previousValue) => {
    let difference = currentValue - previousValue;
    let percentageDifference = (difference / previousValue) * 100;

    return (
      <div>
        <span className={classes.ratingHeadingsLoc}>
          {difference > 0 ? (
            <span
              style={{
                background: "#E3F9F2",
                color: "#13CF8F",
                padding: "4px",
                fontSize: "12px",
                textAlign: "center",
                borderRadius: "5px",
                marginLeft: "3px",
                marginBottom: "3px",
              }}
            >
              <ArrowUpwardIcon className={classes.greenIconClassGraph} />
              {percentageDifference !== Infinity && !isNaN(percentageDifference)
                ? percentageDifference.toFixed() + "%"
                : "100%"}
            </span>
          ) : difference < 0 ? (
            <span
              style={{
                background: "#FFE3E3",
                color: "#FF1616",
                padding: "4px",
                fontSize: "12px",
                textAlign: "center",
                borderRadius: "5px",
                marginLeft: "3px",
                marginBottom: "3px",
              }}
            >
              <ArrowDownwardIcon className={classes.redIconClassGraph} />
              {percentageDifference !== Infinity && !isNaN(percentageDifference)
                ? percentageDifference.toFixed() + "%"
                : "0%"}
            </span>
          ) : difference === 0 ? (
            <span
              style={{
                background: "#FFF7E7",
                color: "#F8A92B",
                padding: "4px",
                fontSize: "12px",
                textAlign: "center",
                borderRadius: "5px",
                marginLeft: "3px",
                marginBottom: "3px",
              }}
            >
              <ArrowForwardIcon className={classes.orangeIconClassGraph} />
              {percentageDifference !== Infinity && !isNaN(percentageDifference)
                ? percentageDifference.toFixed() + "%"
                : "0%"}
            </span>
          ) : (
            ""
          )}
        </span>
      </div>
    );
  };
  const checkValues = (array) => {
    return array.every((item) => {
      return Object.keys(item).every((key) => {
        if (key === "topic") return true;
        return item[key] === null || item[key] === 0;
      });
    });
  };

  return (
    <>
      <Grid container>
        <Grid container>
          <Grid item xs={12} sm={12} md={9.7} lg={9.7}>
            <Box className={classes.headingBox}>
              <Typography className={classes.subHeadRatingReviewDetail}>
                {t("online_reputation")}
              </Typography>
              <BootstrapTooltip title={t("emotion_ai_topics_tooltip")}>
                <Typography className={classes.toolTipIconStyle}>
                  <InfoOutlinedIcon />
                </Typography>
              </BootstrapTooltip>
            </Box>
          </Grid>

          {viewPermission && (
            <Grid item xs={2.3} sm={2.3} md={2.3} lg={2.3} mt={4}>
              <Autocomplete
                id="tags-outlined"
                options={downloadOptions}
                value={downloadValue}
                getOptionLabel={(option) => option.title}
                filterSelectedOptions
                onChange={handleReputationDownloadChange}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t("actionss")} />
                )}
              />
            </Grid>
          )}
        </Grid>
        {topicsDataLoading ? (
          <Box className={classes.reputationLoadingStyle}>
            <WaveSpinner />
          </Box>
        ) : (
          <Grid container>
            {topicsData?.length > 0 && checkValues(topicsData) === false ? (
              <>
                <Grid item xs={6} sm={6} md={6} lg={6} ref={firstGridRef}>
                  <>
                    <Grid container className={classes.tableContainer}>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        lg={2.5}
                        className={classes.mainGridCont}
                      >
                        {/*  <Typography className={classes.tableHeading}>
                          {t("Name")}
                        </Typography> */}
                      </Grid>

                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        className={classes.mainGridCont}
                      >
                        <Typography className={classes.tableHeading}>
                          {t("sentiments")}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        className={classes.mainGridCont}
                      >
                        <Typography className={classes.tableHeading}>
                          {t("mentions")}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3.5}
                        className={classes.mainGridCont}
                      >
                        <Typography className={classes.tableHeading}>
                          {t("Average_Rating")}
                        </Typography>
                      </Grid>
                    </Grid>

                    <>
                      <Grid container className={classes.tableContainer}>
                        {topicsData?.map((item) => {
                          return (
                            <>
                              <Grid
                                item
                                xs={3}
                                sm={3}
                                md={3}
                                lg={2.5}
                                sx={{
                                  padding: "16px",
                                  paddingBottom: "10px",
                                }}
                              >
                                <Typography
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {item?.topic}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3}
                                className={
                                  classes.mainGridContCursorProgressBar
                                }
                                onClick={() => {
                                  setRatingBreakdownValue("");
                                  setSelectedTopicName(item?.topic);
                                  handleOpenReviewModel("new", true);
                                }}
                              >
                                <BootstrapTooltip
                                  title={
                                    <h1 style={{ fontSize: "12px" }}>
                                      {`${t("From")} ${formatDate(
                                        startDate
                                      )} ${t("To")} ${formatDate(endDate)}`}
                                    </h1>
                                  }
                                >
                                  <span
                                    className={classes.ratingHeadings}
                                    style={{ display: "flex" }}
                                    /* style={{
                                      color:
                                        item?.thisMonthRating >= 4.2
                                          ? "#13CF8F"
                                          : item?.thisMonthRating >= 4.0 &&
                                            item?.thisMonthRating < 4.2
                                          ? "#F8A92B"
                                          : "#FF1616",
                                    }} */
                                  >
                                    <Box
                                      sx={{
                                        height: "40px",
                                        width: "40px",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      <CircularProgressbar
                                        justifyContent="center"
                                        styles={buildStyles({
                                          textColor: "#06BDFF",
                                          pathColor: "#06BDFF",
                                          trailColor: "#82deff",
                                          textSize: "30px",
                                        })}
                                        value={Math.round(
                                          item?.currentSatisfactoryLevel
                                        )}
                                        text={`${Math.round(
                                          item?.currentSatisfactoryLevel
                                        )}`}
                                      />
                                    </Box>
                                    <span style={{ marginTop: "8px" }}>
                                      {handleCalculateDifference(
                                        Math.round(
                                          item?.currentSatisfactoryLevel
                                        ),
                                        item?.lastSatisfactoryLevel
                                      )}
                                    </span>
                                  </span>
                                </BootstrapTooltip>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3}
                                className={classes.mainGridContCursor}
                                onClick={() => {
                                  setRatingBreakdownValue("");
                                  setSelectedTopicName(item?.topic);
                                  handleOpenReviewModel("new", true);
                                }}
                              >
                                <BootstrapTooltip
                                  title={
                                    <h1 style={{ fontSize: "12px" }}>
                                      {`${prevTooltipContent}`}
                                    </h1>
                                  }
                                >
                                  <span
                                    className={classes.ratingHeadings}
                                    style={{ display: "flex" }}
                                  >
                                    <span
                                      style={{
                                        color: "#06BDFF",
                                        display: "flex",
                                      }}
                                    >
                                      {item?.mentionedReviews === null
                                        ? 0
                                        : item?.mentionedReviews}
                                    </span>
                                    {handleCalculateDifference(
                                      item?.mentionedReviews,
                                      item?.lastMentionedReviews
                                    )}
                                  </span>
                                </BootstrapTooltip>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3.5}
                                className={classes.mainGridCont}
                                onClick={() => {
                                  setRatingBreakdownValue("");
                                  setSelectedTopicName(item?.topic);
                                  handleOpenReviewModel("new", true);
                                }}
                              >
                                <BootstrapTooltip
                                  title={
                                    <h1 style={{ fontSize: "12px" }}>
                                      {`${tooltipContent}`}
                                    </h1>
                                  }
                                >
                                  <span
                                    className={classes.ratingHeadings}
                                    style={{ display: "flex" }}
                                  >
                                    <span
                                      style={{
                                        color: "#06BDFF",
                                      }}
                                    >
                                      {item?.currentRatingValue === null
                                        ? 0
                                        : item?.currentRatingValue.toFixed(1)}
                                    </span>
                                    {handleCalculateDifference(
                                      item?.currentRatingValue,
                                      item?.lastRatingValue
                                    )}
                                  </span>
                                </BootstrapTooltip>
                              </Grid>
                            </>
                          );
                        })}
                      </Grid>
                    </>
                  </>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.mainGridCont}
                  ></Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      height: "12px",
                    }}
                  ></Grid>
                  <BarChart
                    width={chartWidth}
                    height={chartHeight}
                    data={topicsData}
                    layout="vertical"
                  >
                    <XAxis
                      type="number"
                      domain={[0, "dataMax"]}
                      axisLine={false}
                      tickLine={false}
                    />

                    <YAxis
                      dataKey="topic"
                      type="category"
                      interval={0}
                      textAnchor="end"
                      hide={true}
                    />
                    <Tooltip
                      content={({ active, payload }) => {
                        if (active && payload && payload.length) {
                          return (
                            tooltipData !== null && (
                              <div className={classes.customTooltipReview}>
                                {tooltipData?.oneRatingValue && (
                                  <Typography className={classes.toolTipStyle}>
                                    {t("1_Star")}: {tooltipData?.oneRatingValue}
                                  </Typography>
                                )}
                                {tooltipData?.twoRatingValue && (
                                  <Typography className={classes.toolTipStyle}>
                                    {t("2_Star")}: {tooltipData?.twoRatingValue}
                                  </Typography>
                                )}
                                {tooltipData?.threeRatingValue && (
                                  <Typography className={classes.toolTipStyle}>
                                    {t("3_Star")}:{" "}
                                    {tooltipData?.threeRatingValue}
                                  </Typography>
                                )}
                                {tooltipData?.fourRatingValue && (
                                  <Typography className={classes.toolTipStyle}>
                                    {t("4_Star")}:{" "}
                                    {tooltipData?.fourRatingValue}
                                  </Typography>
                                )}
                                {tooltipData?.fiveRatingValue && (
                                  <Typography className={classes.toolTipStyle}>
                                    {t("5_Star")}:{" "}
                                    {tooltipData?.fiveRatingValue}
                                  </Typography>
                                )}
                              </div>
                            )
                          );
                        }
                        return null;
                      }}
                    />

                    <Bar
                      isAnimationActive={false}
                      dataKey="oneRatingValue"
                      stackId="a"
                      key={Math.random()}
                      fill="#ec2715"
                      barSize={30}
                      onClick={(data) => {
                        setRatingBreakdownValue(1);
                        setSelectedTopicName(data?.topic);
                        handleOpenReviewModel("new", true);
                      }}
                      onMouseEnter={(event) => {
                        if (event.payload && event.payload.oneRatingValue) {
                          setTooltipData({
                            oneRatingValue: event.payload.oneRatingValue,
                          });
                        } else {
                          setTooltipData(null);
                        }
                      }}
                      onMouseLeave={() => {
                        setTooltipData(null);
                      }}
                    >
                      {/* <LabelList
                        dataKey="oneRatingValue"
                        position="inside"
                        fill="#fff"
                        //  formatter={(value) => (value > 0 ? value : null)}
                        formatter={(value) => (value > 0 ? value : "")}
                      /> */}
                    </Bar>
                    <Bar
                      isAnimationActive={false}
                      dataKey="twoRatingValue"
                      stackId="a"
                      fill="#f2742e"
                      barSize={30}
                      onClick={(data) => {
                        setRatingBreakdownValue(2);
                        setSelectedTopicName(data?.topic);
                        handleOpenReviewModel("new", true);
                      }}
                      onMouseEnter={(event) => {
                        if (event.payload && event.payload.twoRatingValue) {
                          setTooltipData({
                            twoRatingValue: event.payload.twoRatingValue,
                          });
                        } else {
                          setTooltipData(null);
                        }
                      }}
                      onMouseLeave={() => {
                        setTooltipData(null);
                      }}
                    >
                      {/* <LabelList
                        dataKey="twoRatingValue"
                        position="inside"
                        fill="#fff"
                        formatter={(value) => (value > 0 ? value : "")}
                      /> */}
                    </Bar>
                    <Bar
                      isAnimationActive={false}
                      dataKey="threeRatingValue"
                      stackId="a"
                      fill="#ffc130"
                      barSize={30}
                      onClick={(data) => {
                        setRatingBreakdownValue(3);
                        setSelectedTopicName(data?.topic);
                        handleOpenReviewModel("new", true);
                      }}
                      onMouseEnter={(event) => {
                        if (event.payload && event.payload.threeRatingValue) {
                          setTooltipData({
                            threeRatingValue: event.payload.threeRatingValue,
                          });
                        } else {
                          setTooltipData(null);
                        }
                      }}
                      onMouseLeave={() => {
                        setTooltipData(null);
                      }}
                    >
                      {/* <LabelList
                        dataKey="threeRatingValue"
                        position="inside"
                        fill="#fff"
                        formatter={(value) => (value > 0 ? value : "")}
                      /> */}
                    </Bar>
                    <Bar
                      isAnimationActive={false}
                      dataKey="fourRatingValue"
                      stackId="a"
                      fill="#8fb02b"
                      barSize={30}
                      onClick={(data) => {
                        setRatingBreakdownValue(4);
                        setSelectedTopicName(data?.topic);
                        handleOpenReviewModel("new", true);
                      }}
                      onMouseEnter={(event) => {
                        if (event.payload && event.payload.fourRatingValue) {
                          setTooltipData({
                            fourRatingValue: event.payload.fourRatingValue,
                          });
                        } else {
                          setTooltipData(null);
                        }
                      }}
                      onMouseLeave={() => {
                        setTooltipData(null);
                      }}
                    >
                      {/* <LabelList
                        dataKey="fourRatingValue"
                        position="inside"
                        fill="#fff"
                        formatter={(value) => (value > 0 ? value : "")}
                      /> */}
                    </Bar>
                    <Bar
                      isAnimationActive={false}
                      dataKey="fiveRatingValue"
                      stackId="a"
                      fill="#239f25"
                      barSize={30}
                      onClick={(data) => {
                        setRatingBreakdownValue(5);
                        setSelectedTopicName(data?.topic);
                        handleOpenReviewModel("new", true);
                      }}
                      onMouseEnter={(event) => {
                        if (event.payload && event.payload.fiveRatingValue) {
                          setTooltipData({
                            fiveRatingValue: event.payload.fiveRatingValue,
                          });
                        } else {
                          setTooltipData(null);
                        }
                      }}
                      onMouseLeave={() => {
                        setTooltipData(null);
                      }}
                    >
                      {/* <LabelList
                        dataKey="fiveRatingValue"
                        position="inside"
                        fill="#fff"
                        formatter={(value) => (value > 0 ? value : "")}
                      /> */}
                    </Bar>
                  </BarChart>
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ display: "flex", width: "70px" }}>
                    <CircleIcon sx={{ color: "#ec2715" }} />
                    <Typography sx={{ color: "#ec2715", marginLeft: "3px" }}>
                      {t("1_Star")}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", width: "70px" }}>
                    <CircleIcon sx={{ color: "#f2742e" }} />
                    <Typography sx={{ color: "#f2742e", marginLeft: "3px" }}>
                      {t("2_Star")}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", width: "70px" }}>
                    <CircleIcon sx={{ color: "#ffc130" }} />
                    <Typography sx={{ color: "#ffc130", marginLeft: "3px" }}>
                      {t("3_Star")}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", width: "70px" }}>
                    <CircleIcon sx={{ color: "#8fb02b" }} />
                    <Typography sx={{ color: "#8fb02b", marginLeft: "3px" }}>
                      {t("4_Star")}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", width: "70px" }}>
                    <CircleIcon sx={{ color: "#239f25" }} />
                    <Typography sx={{ color: "#239f25", marginLeft: "3px" }}>
                      {t("5_Star")}
                    </Typography>
                  </Box>
                </Grid> */}
              </>
            ) : (
              <NoDataFound />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default EmotionAiTopics;
