import React, { useState } from "react";
import data from "../Components/data.json";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Dialog,
  TableRow,
  Typography,
  Tooltip,
  tooltipClasses,
  useMediaQuery,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import { useNavigate } from "react-router-dom";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import { useTheme } from "@mui/material/styles";
import { api } from "../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import { styled } from "@mui/system";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import useDateFormat from "../../../../hooks/useDateFormat";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const Overview = ({
  locationIdValues,

  setIsLoading,
  isLoading,
  campaigns,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  getAllCampaigns,
  getAllFormitableLocations,
  editPermission,
  deletePermission,
  viewPermission,
  addPermission,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedRow, setSelectedRow] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openCampaignModel, setOpenCampaignModel] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [campaignLoading, setCampaignLoading] = useState(false);
  const { formatDate } = useDateFormat();

  const deleteCampaignHandler = async () => {
    setDeleteLoading(true);
    try {
      const res = await api.patch(`/campaign/bulkDelete-campaigns`, {
        all: false,
        campaignIds: [deleteId],
      });
      if (res.status === 200) {
        setOpenDeleteModel(false);
        toast.success(res?.data?.message);
        setDeleteLoading(false);
        getAllCampaigns();
        getAllFormitableLocations();
      }
    } catch (error) {
      setDeleteLoading(false);
      setOpenDeleteModel(false);
      toast.success(error?.response?.data?.message);
    }
  };

  const handleUpdateCampaign = async () => {
    setCampaignLoading(true);
    try {
      const res = await api.patch(`campaign/updateCampaignStatus/${deleteId}`, {
        status: "active",
        location: selectedRow?.campaignLocations[0]?.location,
      });
      if (res.status === 200) {
        setOpenCampaignModel(false);
        toast.success(res?.data?.message);
        setCampaignLoading(false);
        getAllCampaigns();

        // setOpenQRModal(true);
      }
    } catch (error) {
      setOpenCampaignModel(false);
      setCampaignLoading(false);
      toast.success(error?.response?.data?.message);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePrivateNavigate = (id) => {
    navigate(`/user/private-review/${id}`);
  };
  const handleClick = (event, id, data) => {
    setSelectedRow(data);
    setAnchorEl(event.currentTarget);
    setDeleteId(id);
  };

  const handleOpenDeleteModel = (id) => {
    setAnchorEl(null);
    setOpenDeleteModel(true);
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
  };

  const handleOpenCampaignModel = (id) => {
    setAnchorEl(null);
    setOpenCampaignModel(true);
  };

  const handleCloseCampaignModel = () => {
    setOpenCampaignModel(false);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  function handleCalculatePositivePercentage(positiveValue, negativeValue) {
    let percentage = 0;
    const total = positiveValue + negativeValue;
    percentage = ((positiveValue / total) * 100).toFixed();

    return isNaN(percentage) ||
      percentage === Infinity ||
      percentage === -Infinity
      ? 0
      : percentage;
  }
  function handleCalculateNegativePercentage(positiveValue, negativeValue) {
    let percentage = 0;
    const total = positiveValue + negativeValue;
    percentage = ((negativeValue / total) * 100).toFixed();
    return isNaN(percentage) ||
      percentage === Infinity ||
      percentage === -Infinity
      ? 0
      : percentage;
  }
  const headCells = [
    { id: "id", alignment: "left", label: t("IDD") },
    { id: "platform", alignment: "left", label: t("platform") },
    { id: "type", alignment: "left", label: t("type") },
    { id: "locationName", alignment: "left", label: t("location_name") },
    { id: "createdBy", alignment: "left", label: t("created_by") },
    { id: "createdAt", alignment: "left", label: t("created_at") },
    { id: "impressions", alignment: "left", label: t("impressions") },
    { id: "clicks", alignment: "left", label: t("clicks") },
    { id: "positive", alignment: "left", label: t("positive") },
    { id: "negative", alignment: "center", label: t("negative") },
    { id: "status", alignment: "center", label: t("status") },
    { id: "actions", alignment: "center", label: t("actionss") },
  ];
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data?.count - page * rowsPerPage);

  const handleCountClicks = (arr) => {
    const updateList = arr?.filter((item) => item?.survey_reach !== "pending");
    const positiveList = updateList?.filter(
      (item) => item?.survey_reach === "positive"
    );
    const negativeList = updateList?.filter(
      (item) => item?.survey_reach === "negative"
    );
    return positiveList.length + negativeList.length;
  };

  return (
    <>
      {openCampaignModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openCampaignModel}
          onClose={handleCloseDeleteModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Start Campaign")}
            description={`Are you sure you want to start Campaign?`}
            onConfirm={() => {
              handleUpdateCampaign();
            }}
            loading={campaignLoading}
            onCancel={handleCloseCampaignModel}
          />
        </Dialog>
      )}
      {openDeleteModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Delete_Campaign")}
            description={t("delete_campaign_subhead")}
            onConfirm={() => {
              deleteCampaignHandler();
            }}
            loading={deleteLoading}
            onCancel={handleCloseDeleteModel}
          />
        </Dialog>
      )}

      <>
        <TableContainer component={Paper} sx={{ marginTop: "1rem" }}>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <CustomizedTableHead>
              <CustomizedTableRow>
                {headCells.map((headCell) => (
                  <CustomizedTableCell
                    sx={{ fontWeight: 700 }}
                    key={headCell.id}
                    align={headCell.alignment}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </CustomizedTableCell>
                ))}
              </CustomizedTableRow>
            </CustomizedTableHead>

            <TableBody>
              {campaigns?.results?.length > 0 &&
                campaigns?.results?.map((row, index) => {
                  /*    const isItemSelected = isSelected(row.name);
                        const labelId = `enhanced-table-checkbox-${index}`; */

                  const isFormitableFound =
                    row?.campaign_type === "formitable" &&
                    row?.campaignLocations?.length > 0 &&
                    row?.campaignLocations[0]?.location !== null &&
                    row?.campaignLocations[0]?.location?.socialLink?.length >
                      0 &&
                    row.campaignLocations[0]?.location.socialLink?.some(
                      (item) => item?.type === "formitable"
                    );

                  return (
                    <CustomizedTableRow
                      sx={{ fontWeight: "700px", color: "#1B2430" }}
                      // hover
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                    >
                      <CustomizedTableCell sx={{ cursor: "pointer" }}>
                        {row?.id}
                      </CustomizedTableCell>
                      <CustomizedTableCell sx={{ cursor: "pointer" }}>
                        {row?.platform === "google" ? t("Google") : ""}
                      </CustomizedTableCell>
                      <CustomizedTableCell
                        sx={{ cursor: "pointer" }}
                        align="left"
                      >
                        <Typography
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          {row?.campaign_type === "integration"
                            ? t("Integration")
                            : row?.campaign_type === "import-email"
                            ? t("Import_Email")
                            : row?.campaign_type === "qr-code"
                            ? t("qr_code")
                            : row?.campaign_type === "formitable"
                            ? "Formitable"
                            : row?.campaign_type}

                          {row?.campaign_type === "formitable" &&
                            isFormitableFound === true &&
                            row?.campaignLocations?.length > 0 &&
                            row?.campaignLocations[0]?.location !== null &&
                            row?.campaignLocations[0]?.location?.socialLink
                              ?.length > 0 &&
                            !row?.campaignLocations[0]?.location?.socialLink.some(
                              (item) => item?.type === "formitable"
                            ) && (
                              <BootstrapTooltip
                                title={`${t("formitable_Disconnected")}`}
                              >
                                <ErrorOutlineIcon
                                  fontSize="small"
                                  sx={{
                                    marginLeft: "3px",
                                    color: "#FF1616",
                                  }}
                                />
                              </BootstrapTooltip>
                            )}
                        </Typography>
                      </CustomizedTableCell>
                      <CustomizedTableCell
                        sx={{ cursor: "pointer", width: "200px" }}
                        align="left"
                      >
                        {row?.campaignLocations?.length > 0
                          ? row?.campaignLocations?.map(
                              (item) => item?.location?.internalName
                            )
                          : "--"}
                      </CustomizedTableCell>
                      <CustomizedTableCell
                        sx={{ cursor: "pointer" }}
                        align="left"
                      >
                        {row?.users !== null
                          ? row?.users?.firstName.charAt(0).toUpperCase() +
                            row?.users?.firstName.slice(1)
                          : "--"}
                      </CustomizedTableCell>
                      <CustomizedTableCell
                        sx={{ cursor: "pointer" }}
                        align="left"
                      >
                        {formatDate(row?.createdAt)}
                      </CustomizedTableCell>
                      <CustomizedTableCell align="left">
                        {row?.status === "pending"
                          ? 0
                          : row?.campaign_type === "qr-code"
                          ? row?.qrCount
                          : row?.reach}
                      </CustomizedTableCell>
                      <CustomizedTableCell align="left">
                        {row?.campaign_type === "qr-code"
                          ? row?.qrPositiveCount + row?.qrNegativeCount
                          : handleCountClicks(row?.campaignDataUser)}
                      </CustomizedTableCell>
                      <CustomizedTableCell
                        align="left"
                        sx={{ color: "#0FA672" }}
                      >
                        {row?.campaign_type === "qr-code"
                          ? handleCalculatePositivePercentage(
                              row?.qrPositiveCount,
                              row?.qrNegativeCount
                            ) + "%"
                          : row?.positive !== null
                          ? row?.positive + "%"
                          : "0%"}
                      </CustomizedTableCell>
                      <CustomizedTableCell
                        align="center"
                        sx={{ color: "#FF1616" }}
                      >
                        {row?.campaign_type === "qr-code"
                          ? handleCalculateNegativePercentage(
                              row?.qrPositiveCount,
                              row?.qrNegativeCount
                            ) + "%"
                          : row?.negative !== null
                          ? row?.negative + "%"
                          : "0%"}
                      </CustomizedTableCell>

                      <CustomizedTableCell
                        align="center"
                        sx={{
                          color:
                            row.campaign_type === "formitable" &&
                            isFormitableFound === true
                              ? "#13CF8F"
                              : row.campaign_type === "formitable" &&
                                isFormitableFound === false
                              ? "#F8A92B"
                              : row?.status === "pending"
                              ? "#F8A92B"
                              : "" || row?.status === "active"
                              ? "#13CF8F"
                              : "" || row?.status === "disable"
                              ? "#FC3652"
                              : "",
                        }}
                      >
                        {row.campaign_type === "formitable" &&
                        isFormitableFound === true
                          ? t("Active")
                          : row.campaign_type === "formitable" &&
                            isFormitableFound === false
                          ? t("Pendingg")
                          : row?.status === "active"
                          ? t("Active")
                          : row?.status === "pending"
                          ? t("Pendingg")
                          : "--"}
                      </CustomizedTableCell>

                      <CustomizedTableCell align="center">
                        <IconButton
                          onClick={(e) => handleClick(e, row?.id, row)}
                          variant="outlined"
                        >
                          <MoreHorizIcon
                            id="demo-positioned-button"
                            aria-controls={
                              open ? "demo-positioned-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            // onClick={handleClick}
                          />
                        </IconButton>

                        {viewPermission && editPermission ? (
                          <BootstrapTooltip title={t("Edit_campaign")}>
                            <IconButton
                              variant="outlined"
                              onClick={() =>
                                navigate(
                                  `/user/review_request_campaign/${row?.id}`
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          </BootstrapTooltip>
                        ) : (
                          <BootstrapTooltip title={t("authorized_access")}>
                            <span>
                              <MenuItem disabled>
                                <IconButton variant="outlined">
                                  <EditIcon />
                                </IconButton>
                              </MenuItem>
                            </span>
                          </BootstrapTooltip>
                        )}
                        <Menu
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <MenuItem
                            onClick={() => handlePrivateNavigate(deleteId)}
                          >
                            {t("Private_review")}
                          </MenuItem>

                          {selectedRow?.status === "pending" && (
                            <MenuItem
                              onClick={() => {
                                handleOpenCampaignModel();
                                closeMenu();
                                handleClose();
                                // setDeleteId(row?.id);
                              }}
                            >
                              {t("Start_Campaign")}
                            </MenuItem>
                          )}

                          {viewPermission && deletePermission ? (
                            <MenuItem
                              onClick={() => {
                                closeMenu();
                                handleOpenDeleteModel(deleteId);
                              }}
                            >
                              <Typography
                                aria-label="delete"
                                size="large"
                                onClick={() => {
                                  handleOpenDeleteModel();
                                }}
                              >
                                {t("Delete")}
                              </Typography>
                            </MenuItem>
                          ) : (
                            <BootstrapTooltip title={t("authorized_access")}>
                              <span>
                                <MenuItem disabled>
                                  <Typography aria-label="delete" size="large">
                                    {t("Delete")}
                                  </Typography>
                                </MenuItem>
                              </span>
                            </BootstrapTooltip>
                          )}
                          {viewPermission && addPermission ? (
                            <MenuItem
                              onClick={() =>
                                navigate(
                                  `/user/review_request_campaign/duplicate/${deleteId}`
                                )
                              }
                            >
                              <Typography aria-label="delete" size="large">
                                {t("Duplicate_Campaign")}
                              </Typography>
                            </MenuItem>
                          ) : (
                            <BootstrapTooltip title={t("authorized_access")}>
                              <span>
                                <MenuItem disabled>
                                  <Typography aria-label="delete" size="large">
                                    {t("Duplicate_Campaign")}
                                  </Typography>
                                </MenuItem>
                              </span>
                            </BootstrapTooltip>
                          )}
                        </Menu>
                      </CustomizedTableCell>
                    </CustomizedTableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>{/* <TableCell colSpan={8} /> */}</TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={campaigns?.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>

      {/* <QRCodeModal
        open={openQRModal}
        setOpenQRModal={setOpenQRModal}
        campaignQRId={deleteId}
        callApi={true}
        getAllCampaigns={getAllCampaigns}
      /> */}
    </>
  );
};

export default Overview;
