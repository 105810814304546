import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { GraphicEq } from "@mui/icons-material";
import { borderRadius, position } from "polished";

const color = {
  darkGrey: "#1B2430",
  blue: "#06BDFF",
  grey: "#495059",
  white: "#FFFFFF",
  lightGrey: "#8D9298",
  darkPink: "#FC3652",
  lightGreen: "#13CF8F",
  darkBlue: "#0638C1",
  lightBlue: "#06BDFF",
  lightGray: "#E0E0E0",
  skyBlue: "#066DE8",
  mustard: "#F8A92B",
  gray: "#F6F6F7",
  graphite: "#545353",
  magenta: "#b02639",
  lightPink: "#FFD0D0",
  babypink: "#ea99a2",
  maroon: "#330404",
  red: "#FF1616",
  darkGray: "#cbd5e1",
  darkGraphite: "#f8fafc",
  darkRed: "#FF0000",
};

export const useStyles = makeStyles((theme) => ({
  box: {
    height: "100vh",
    width: "500px",
    bgcolor: "background.paper",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  tagsInputContainer: {
    border: "1px solid #c7c7c7",
    borderRadius: "4px",
    padding: "0.5em",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "4px",
    "&:hover": {
      border: "1px solid #000000",
    },
  },
  cropperButton: {
    borderColor: "#3c98eb",
    fontWeight: "600",
    borderRadius: "8px",
    fontSize: "1rem",
    lineHeight: "1.5",
    padding: "0.5rem 1rem",
    marginLeft: "4px",
    height: "50",
  },
  locationbox: {
    display: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "50%",
    margin: "auto",
  },
  cardbox: {
    maxHeight: "100vh",
    height: "auto",
    overflowY: "scroll",
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },

  // cardcontent: {
  //   marginBottom: "8px",
  //   // bgcolor: "white",
  //   display: "flex",
  //   padding: "8px",
  //   cursor: "pointer",

  //   alignItems: "center",
  //   justifyContent: "space-between",
  //   cursor: "pointer",
  // },

  subheadName: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.grey,
    textAlign: "center",
  },
  locationAddress: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: color.grey,
    // textAlign: "center",
  },
  subheadNavigateCards: {
    fontSize: "16px",
    fontWeight: 700,
    color: color.darkGrey,
  },
  subheadGoogleName: {
    fontWeight: 700,
    fontSize: "20px",
    color: color.darkGrey,
    lineHeight: "28px",
    letterSpacing: "0.5px",
    marginBottom: "16px",
  },
  subheadGoogletext: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#495059",
    lineHeight: "18px",
  },
  headLocationName: {
    fontWeight: 700,
    fontSize: "18px",
    color: color.darkGrey,
    textTransform: "capitalize",
  },
  subheadGoogletext: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#495059",
    lineHeight: "18px",
  },
  headRatingName: {
    fontWeight: 700,
    fontSize: "32px",
    color: color.darkGrey,
  },
  subHeadRatingName: {
    fontWeight: 400,
    // marginTop: "8px",
    lineHeight: "14px",
    fontSize: "10px",
    color: color.darkGrey,
  },
  subHeadLocationName: {
    fontWeight: 400,
    fontSize: "14px",
    color: color.grey,
  },
  locationListCard: {
    backgroundColor: theme.palette.mode === "dark" ? "#20232a" : "white",
    borderRadius: "8px",
    height: "100%",
  },

  fieldhead: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    color: theme.palette.mode === "dark" ? "#FFF" : color?.darkGrey,
    padding: "8px 8px 1px 8px",
  },
  fieldheadAccounts: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    color: theme.palette.mode === "dark" ? "#FFF" : color?.darkGrey,
    padding: "0px 8px 1px 8px",
  },
  nameContainer: {
    "&:hover ": {
      // background: "#F8F8F8",
      //  borderRadius: "8px",
      //  color: "white",
    },
  },
  iconContainer: {
    "&:hover ": {
      // background: "#F8F8F8",
      //   color: "#0300FF",
      //  borderRadius: "8px",
      //  color: "white",
    },
  },
  iconStyle: {
    color: color?.grey,
    marginRight: "5px",
  },
  editButton: {
    padding: "14px 20px",
    width: "140px",
    height: "48px",
    background: color?.blue,
    borderRadius: "8px",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
  },
  ratingContainer: {
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  commonButton: {
    marginRight: "5px",
  },

  cardcontent_map: {
    marginBottom: "8px",
    bgcolor: color?.white,
    display: "flex",
    padding: "14px",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "8px",
    cursor: "pointer",
  },
  titleName_map: {
    fontWeight: 600,
    fontSize: "16px",
    color: color?.white,
    lineHeight: "24px",
  },
  subheadName_map: {
    fontWeight: 400,
    color: color?.white,
    fontSize: "14px",
    lineHeight: "24px",
  },

  locationContainer_map: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    position: "relative",
    bottom: 100,
    borderRadius: "8px",
    padding: "20px",
  },

  heading: {
    fontWeight: 400,
    fontSize: "11px",
    textDecoration: "none",
    lineHeight: "20px",
    marginTop: "10px",
    color: color.grey,
  },

  chartSubHeading: {
    fontWeight: 700,
    fontSize: "18px",
    textDecoration: "none",
    lineHeight: "18px",
    color: color?.darkGrey,
  },
  tooltipstyle: {
    fontSize: "large",
    paddingBottom: "3px",
    color: color?.lightGrey,
  },

  chartSubHeadingPercentNegative: {
    fontWeight: 400,
    fontSize: "12px",
    textDecoration: "none",
    lineHeight: "18px",
    color: color?.darkPink,
    //  paddingTop: "15px",
  },
  chartSubHeadingPercentPositive: {
    fontWeight: 400,
    fontSize: "12px",
    textDecoration: "none",
    lineHeight: "18px",
    color: color?.lightGreen,
    // paddingTop: "15px",
  },

  locationDetailBox: {
    width: "100%",
    paddingTop: "0px",
    paddingLeft: "10px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  percentageBox: {
    display: "flex",
    // paddingTop: "10px",
  },
  mainGrid: {
    display: "flex",
    justifyContent: "space-between",
    width: "100px",
  },
  cardNavigationcontent: {
    paddingTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "10px",
    height: "120px",
  },
  subheadNavigationCards: {
    fontSize: "16px",
    fontWeight: 700,
    color: color?.darkGrey,
    lineHeight: "24px",
  },
  LinkStyle: {
    textDecoration: "none",
  },
  linkText: {
    textTransform: "capitalize",
    textDecoration: "none",
    display: "flex",
    color: color?.darkBlue,
  },
  headLocationNameRating: {
    fontWeight: 700,
    fontSize: "18px",
    color: color?.darkGrey,
    textTransform: "capitalize",
  },
  headRatingName: {
    fontWeight: 700,
    fontSize: "32px",
    color: color?.darkGrey,
  },
  subHeadRatingName: {
    fontWeight: 400,
    // marginTop: "8px",
    lineHeight: "14px",
    fontSize: "10px",
    color: color?.darkGrey,
  },
  subHeadLocationName: {
    fontWeight: 400,
    fontSize: "14px",
    color: color?.grey,
  },

  editButtonMap: {
    padding: "14px 20px",
    width: "100%",
    height: "48px",
    background: color?.lightBlue,
    borderRadius: "8px",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
  },
  containergraphconfig: {
    opacity: "0.4",
  },
  headingPieChart: {
    fontWeight: 400,
    fontSize: "14px",
    textDecoration: "none",
    lineHeight: "20px",
    marginTop: "2px",

    color: color.grey,
  },

  pieChartSubHeading: {
    fontWeight: 700,
    fontSize: "32px",
    textDecoration: "none",
    lineHeight: "40px",

    color: color?.darkGrey,
  },
  chartSubHeadingconfig: {
    fontWeight: 700,
    fontSize: "32px",
    textDecoration: "none",
    lineHeight: "40px",
    color: color?.lightGray,
  },

  locationPieChartDetailBox: {
    width: "100%",
    paddingTop: "0px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "20px",
  },
  subheadGoogleNamePie: {
    fontWeight: 700,
    fontSize: "20px",
    color: color?.darkGrey,
    lineHeight: "28px",
    letterSpacing: "0.5px",
    // marginBottom: "16px",
  },
  pieChartDiv: {
    width: "80%",
    margin: "auto",
  },
  namePieChart: {
    height: "12px",
    width: "12px",
    backgroundColor: color?.skyBlue,
    marginRight: "5px",
    borderRadius: "50%",

    display: "inline-block",
  },
  categoryPieChart: {
    height: "12px",
    width: "12px",
    backgroundColor: color?.lightBlue,
    borderRadius: "50%",
    marginRight: "5px",
    display: "inline-block",
  },
  brandPieChart: {
    height: "12px",
    width: "12px",
    backgroundColor: color?.mustard,
    borderRadius: "50%",
    marginRight: "5px",
    display: "inline-block",
  },
  percentageBoxPie: {
    display: "flex",
    paddingTop: "10px",
    marginLeft: "10px",
  },
  chartSubHeadingPercentNegativePie: {
    fontWeight: 400,
    fontSize: "12px",
    textDecoration: "none",
    lineHeight: "18px",
    color: color?.darkPink,
    //  paddingTop: "15px",
  },
  chartSubHeadingPercentPositivePie: {
    fontWeight: 400,
    fontSize: "12px",
    textDecoration: "none",
    lineHeight: "18px",
    color: color?.lightGreen,
    // paddingTop: "15px",
  },
  mainGridPieChart: {
    display: "flex",
  },
  cardReviews: {
    backgroundColor: color?.gray,
    borderRadius: "none",

    height: "auto",
  },

  headReviewsName: {
    fontWeight: 700,
    fontSize: "32px",
    color: color?.darkGrey,
  },
  subHeadReviewsNameBox: {
    fontWeight: 700,
    // marginTop: "8px",
    lineHeight: "24px",
    fontSize: "18px",
    color: color?.darkGrey,
    marginTop: "10px",
    marginBottom: "4px",
  },
  headingAccount: {
    fontSize: "18px",
    fontWeight: 600,
  },
  cardContentAccount: {
    height: "auto",
    width: "100%",
    padding: "30px",
  },
  accountsContainer: {
    paddingTop: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  accountsDiv: {
    padding: "10px",
    display: "flex",
  },
  accountName: {
    fontSize: "13px",
    fontWeight: 600,
    color: color?.graphite,
    textTransform: "capitalize",
  },
  buttonContainerAccount: {
    padding: "10px",
  },

  inputRoot: {
    color: "inherit",
  },

  buttonContainerAccount: {
    padding: "10px",
  },
  MainBoxLocation: {
    padding: "24px",
  },

  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    backArrow: {
      height: "40px",
      color: "blue",
    },
    textField: {
      height: "40px",
      backgroundColor: "red",
    },
  },

  UnsubscribeButton: {
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 20px",
    width: "117px",
    height: "48px",
    background: color?.lightBlue,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.white,
    "&:hover ": {
      background: color?.darkBlue,
      borderRadius: "8px",
      color: color?.white,
    },
  },
  cancelButton: {
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 20px",
    width: "100px",
    height: "48px",
    background: color?.white,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.darkGrey,
    border: "1px solid #E0E0E0",
    "&:hover ": {
      background: "#c2cbcf",
      borderRadius: "8px",
      color: "white",
    },
  },
  errorText: {
    fontWeight: 400,
    // fontSize: "0.8125rem",
    lineHeight: "20px",
    color: color?.red,
    fontSize: "12px",
    padding: "8px 5px",
  },
  warningText: {
    fontWeight: 400,
    // fontSize: "0.8125rem",
    lineHeight: "20px",
    color: "#F8A92B",
    fontSize: "12px",
    padding: "8px 5px",
  },

  actionButtons: {
    padding: "20px 16px",
    gap: "16px",
    width: "100%",
    height: "100%",
    background: color?.white,
    boxShadow: "0px -1px 0px #E0E0E0",
  },
  errorGrid: {
    justifyContent: "center",
    textAlign: "center",
    padding: "10px 5px 0px 5px",
    cursor: "pointer",
  },
  subscribedText: {
    textAlign: "center",
    background: color?.lightBlue,
    color: color?.white,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "14px",
    padding: "3px 6px",
    gap: "4px",
    width: "67px",
    height: "20px",
    borderRadius: "800px",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },

  mainContainer: {
    padding: "20px",
  },

  UnsubscribeButtonReview: {
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 20px",
    width: "134px",
    height: "48px",
    background: color?.lightBlue,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.white,
    "&:hover ": {
      background: color?.lightBlue,
      borderRadius: "8px",
      color: color?.white,
    },
  },
  logoDeleteButton: {
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 20px",
    width: "93px",
    height: "48px",
    background: "#FC3652",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FFFFFF",
    "&:hover ": {
      background: "#b02639",
      borderRadius: "8px",
      color: "#FFFFFF",
    },
  },

  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
  },

  textLimitTypo: {
    fontSize: "12px",
    fontWeight: "400",
    color: color?.darkRed,
    lineHeight: "15px",
    marginBottom: "8px",
  },
  googleBox: {
    bgcolor: color?.gray,
    width: "100%",
    borderRadius: "8px",
    marginY: "8px",
  },
  titleName: {
    fontWeight: "700",
    fontSize: "20px",
    color: "#1B2430",
  },

  headName: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B2430",
  },
  headNameAdress: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B2430",
  },
  headNameDesc: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#06BDFF",
    display: "column",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  headNameDescAttr: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#06BDFF",
    display: "column",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    // paddingLeft: "25px",
  },
  tagsText: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "2px",
  },
  tagsItem: {
    background: "#EBEBEC",
    minWidth: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "700px",
    flexWrap: "nowrap",
  },
  titleNameCategories: {
    fontWeight: "700",
    fontSize: "20px",
    color: "#1B2430",
  },
  modalHeader: {
    fontSize: "20px",
    color: "#1B2430",
    fontWeight: "700",
  },
  modalSubheader: {
    fontSize: "14px",
    color: "#495059",
    fontWeight: "400",
  },
  LocalPagesModalSubheader: {
    fontSize: "14px",
    color: "#1B2430",
    fontWeight: "400",
  },
  fieldTextCategories: {
    fontSize: "14px",
    fontWeight: "700",
  },

  tagsTextBrands: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "2px",
    display: "column",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  moreHoursText: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "2px",
    color: "#1B2430",
  },
  tagsItemBrand: {
    background: "#EBEBEC",
    minWidth: "101px",
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingRight: "6px",
    paddingLeft: "6px",
    margin: "0.3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "800px",
    marginLeft: "0px",
  },
  tagsBoxCategories: {
    display: "flex",
    flexWrap: "wrap",
  },
  headNameValue: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B2430",
    wordBreak: "break-all",
    wordWrap: "break-word",
    // textAlign: "center",
    paddingLeft: "25px",
  },
  headNameValue1: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B2430",
    wordBreak: "break-all",
    wordWrap: "break-word",
    textAlign: "right",
    paddingRight: "13px",
  },
  tagsTextAttributes: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "2px",
  },
  tagsItemAttributes: {
    background: "#EBEBEC",
    minWidth: "101px",
    padding: "6px",
    margin: "0.3rem",
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "800px",
  },
  tagsBoxAttributes: {
    display: "flex",
    flexWrap: "wrap",
  },
  fieldTextDetails: {
    fontSize: "14px",
    fontWeight: "700",
  },
  tagsItemDesc: {
    background: "#EBEBEC",
    minWidth: "101px",
    padding: "6px",
    margin: "0.3rem",
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "800px",
  },
  tagsBoxDesc: {
    display: "flex",
    flexWrap: "wrap",
  },
  tagsTextDesc: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "2px",
  },

  fieldTextDescription: {
    fontSize: "14px",
    fontWeight: "700",
    textTransform: "capitalize",
  },
  tagsText: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "2px",
  },
  tagsItem: {
    background: "#EBEBEC",
    minWidth: "101px",
    padding: "6px",
    margin: "0.3rem",
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "800px",
  },
  tagsItemss: {
    background: "#EBEBEC",
    minWidth: "101px",
    padding: "6px",
    margin: "0.3rem",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginLeft: "2px",
    marginRight: "2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "800px",
  },
  tagsBoxDetails: {
    display: "flex",
    flexWrap: "wrap",
  },
  tagsTextDetails: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "2px",
  },
  Details: {
    fontSize: "16px",
    fontWeight: "700",
  },
  fieldTextDetails: {
    fontSize: "14px",
    fontWeight: "700",
  },
  tagsItemDetails: {
    background: "#EBEBEC",
    minWidth: "101px",
    padding: "6px",
    margin: "0.3rem",
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "800px",
  },
  tagsBoxDetails: {
    display: "flex",
    flexWrap: "wrap",
  },
  tagsTextDetails: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "2px",
  },
  headNameDescDetails: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#06BDFF",
    display: "column",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  tagsText: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "2px",
  },
  tagsItemService: {
    background: "#EBEBEC",
    minWidth: "101px",
    padding: "6px",
    margin: "0.3rem",
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "800px",
  },
  tagsBox: {
    display: "flex",
    flexWrap: "wrap",
  },
  fieldTextService: {
    fontSize: "14px",
    fontWeight: "700",
  },
  fieldTextOptions: {
    fontSize: "14px",
    fontWeight: "700",
  },
  fieldTextSocial: {
    fontSize: "14px",
    fontWeight: "700",
  },
  headNameDescSocial: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#06BDFF",
    display: "column",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  indicator: {
    backgroundColor: "#F6F6F7",
    textAlign: "left",
  },
  tabRoot: {
    textAlign: "left",
    alignItems: "flex-start",
    "&:hover": {
      color: "#1B2430",
      alignItems: "flex-start",
      opacity: 1,
    },
    wrapper: {
      alignItems: "self-start",
      justifyContent: "flex-start",
    },
  },
  button: {
    alignItems: "flex-start",
  },
  wrapper: {
    alignItems: "flex-start",
  },
  selectedTab: {
    color: "#E8F2FF",
    backgroundColor: "#E8F2FF",
  },

  fieldText: {
    fontSize: "14px",
    fontWeight: "700",
  },
  boxModal: {
    width: "auto",
  },
  modalDialogContent: {
    lineHeight: "28px",
    fontWeight: "700",
    fontSize: "20px",
    color: "#1b2430",
    width: "1100px",
    height: "100%",
  },
  //modal
  deleteDialogContent: {
    fontWeight: "400",
    lineHeight: "20px",
    fontSize: "14px",
    marginTop: "24px",
    marginLeft: "1rem",
  },
  deleteButton: {
    fontWeight: "700",
    fontSize: "14px",
    marginTop: "6px",
  },
  /*  modalContent: {
    height:"80vh",
    maxHeight: "80vh",
  }, */
  addButton: {
    color: "#06BDFF",
    fontWeight: 700,
    fontSize: "14px",
    marginLeft: "3px",
  },
  addRangeButton: {
    backgroundColor: "#06BDFF",
    color: "#ffffff",
    fontWeight: 700,
    fontSize: "14px",
    marginLeft: "3px",
    padding: "9px 18px",
    borderRadius: "7px",
    marginLeft: "8px",
    "&:hover ": {
      backgroundColor: "#06BDFF",
    },
  },
  addRangeButtonText: {
    fontSize: "14px",
    fontWeight: 700,
  },
  addButtonBulk: {
    color: "#06BDFF",
    fontWeight: 700,
    fontSize: "14px",
    paddingLeft: "0px",
    // marginLeft: "3px",
  },
  addIconDisabled: {
    color: color?.lightGrey,
    marginLeft: "3px",
  },
  addIcon: {
    color: "#06BDFF",

    marginLeft: "3px",
  },
  addIconBulk: {
    color: "#06BDFF",

    //  marginLeft: "3px",
  },

  addButtonDisabled: {
    color: color?.lightGrey,
    fontWeight: 700,
    fontSize: "14px",
    marginLeft: "3px",
  },
  actionContent: {
    maxHeight: "480px",
    height: "480px",
    overflow: "auto",
  },
  actionContentHours: {
    maxHeight: "410px",
    height: "410px",
    overflow: "auto",
  },
  actionContentLodging: {
    maxHeight: "470px",
    height: "470px",
    overflow: "auto",
  },
  actionContentAttributes: {
    maxHeight: "340px",
    height: "340px",
    overflow: "auto",
  },
  mainActionContentAttributes: {
    height: "340px",
  },
  limitFieldTextDescription: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#1B2430",
    lineHeight: "20px",
    marginTop: "2px",
    paddingLeft: "3px",
  },
  toggleChecked: {
    "& .MuiSwitch-thumb": {
      color: "#EBEBEC",
      //  transform:'translateX(25px) !important'
    },
  },
  toggleUnChecked: {
    "& .MuiSwitch-thumb": {
      color: color?.blue,
      //  transform:'translateX(25px) !important'
    },
  },
  toggleDisabled: {
    "& .MuiSwitch-thumb": {
      color: "#8D9298",
      //  transform:'translateX(25px) !important'
    },
  },
  adressField: {
    marginTop: "7px",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "24px",
    gap: "9px",
  },
  syncButton: {
    fontSize: "14px",
    borderRadius: "16px",
    height: "52px",
    marginLeft: "10px",
    fontWeight: "700",
    margin: "auto",
  },
  accordianText: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#1B2430",
  },
  viewMoreButton: {
    fontWeight: 700,
    fontSize: "14px",
    textAlign: "start",
  },
  viewMoreButtonService: {
    fontWeight: 700,
    fontSize: "12px",
    // textAlign: "start",
  },
  tagItem: {
    backgroundColor: "#ebebec",
    borderRadius: "800px",
    display: "inline-block",
    padding: "4px 8px",
    fontSize: "12px",
    color: "#1B2430",
    fontWeight: "400",
  },
  tagItemnew: {
    backgroundColor: "#ebebec",
    borderRadius: "800px",
    display: "inline-block",
    padding: "4px 8px",
    fontSize: "12px",
    color: "#1B2430",
    fontWeight: "400",
    marginLeft: "2px",
    marginRight: "2px",
  },
  Close: {
    height: "3px",
    width: "9px",
    color: "#1B2430",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "0.5rem",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "400",
    padding: "3px",
  },
  tagsInput: {
    flexGrow: 1,
    padding: "0.5em 0",
    border: "none",
    outline: "none",
    marginTop: "8px",
    overflow: "hidden",
  },
  tagsInputBulk: {
    flexGrow: 1,
    border: "none",
    outline: "none",
    overflow: "hidden",
  },
  helpingTextkeyword: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#8D9298",
    paddingTop: "5px",
  },
  colapsibleTitle: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#1B2430",
    paddingLeft: "4px",
  },
  progressCardCompleteTitle: {
    fontSize: "20px",
    fontWeight: "900",
    color: "#FFFFFF",
  },
  progressCardTitle: {
    fontSize: "18px",
    fontWeight: "700",
    color: "#FFFFFF",
  },
  progressCardPara: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#FFFFFF",
  },
  keywordTypo: {
    fontSize: "12px",
    paddingX: "4px",
    fontWeight: "400",
    marginX: "4px",
  },
  keywordIcon: {
    marginLeft: "6px",
    fontSize: "16px",
    color: "#1B2430",
    cursor: "pointer",
    "&:hover ": {
      color: "#06BDFF",
    },
  },
  listingCard: {
    bgcolor: "#ffff",
    borderRadius: "8px",
    height: "auto",
  },
  gridButtons: {
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "end",
    padding: "18px",
    alignSelf: "end",
  },
  gridButtonsSocial: {
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "end",
    padding: "18px",
    allignItems: "center",
    fontSize: "16px",
    fontWeight: "400",
    cursor: "pointer",
  },
  keywordBox: {
    alignItems: "center",
    display: "flex",
    paddingX: "8px",
    paddingY: "6px",
  },
  keywordBoxx: {
    alignItems: "center",
    display: "flex",
    paddingX: "8px",
    paddingY: "6px",
    marginLeft: "2px",
    marginRight: "2px",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  switchTypo: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#1B2430",
  },
  loaderBox: {
    padding: "18px",
    paddingTop: "150px",
    width: "100%",
  },
  selectedAttributes: {
    fontWeight: 700,
  },
  unSelectedAttributes: {
    fontWeight: 400,
  },
  chartHeading: {
    fontWeight: "700",
    fontSize: "20px",
    color: "#1B2430",
    lineHeight: "28px",
    //  marginTop: "10px",
    letterSpacing: "0.5 px",
  },
  headingChartBox: {
    display: "flex",
    alignItems: "center",
    fontWeight: 400,
    fontSize: "14px",
    textDecoration: "none",
    lineHeight: "18px",
    marginTop: "10px",
    color: "#495059",
  },
  headingMobile: {
    color: "#F86F35",
    paddingLeft: "3px",
    fontSize: "14px",
    fontWeight: "400",
  },
  headingMobileMap: {
    color: "#0638C1",
    paddingLeft: "3px",
    fontSize: "14px",
    fontWeight: "400",
  },
  headingConversion: {
    color: "#3ad7a2",
    paddingLeft: "3px",
    fontSize: "14px",
    fontWeight: "400",
  },
  headingWeb: {
    color: "#f8a92b",
    paddingLeft: "3px",
    fontSize: "14px",
    fontWeight: "400",
  },
  resultsTabs: {
    fontWeight: "700",
    fontSize: "18px",
    color: "#495059",
  },
  totalValuesubText: {
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    color: "#495059",
  },
  visibilityIcon: {
    color: "#495059",
    marginRight: "4px",
  },
  totalValueText: {
    fontSize: "32px",
    fontWeight: "700",
    paddingY: "4px",
    paddingBottom: "0px",
  },
  graphGrid: {
    paddingY: "16px",
    marginTop: "21px",
  },
  headingWebsite: {
    color: "#0638C1",
    paddingLeft: "3px",
    fontSize: "14px",
    fontWeight: "400",
  },
  headingDesktop: {
    color: "#FC3652",
    paddingLeft: "3px",
    fontSize: "14px",
    fontWeight: "400",
  },
  headingDesktopMap: {
    paddingLeft: "3px",
    color: "#06BDFF",
    fontSize: "14px",
    fontWeight: "400",
  },

  chartSubHeading: {
    fontWeight: 700,
    fontSize: "28px",
    textDecoration: "none",
    lineHeight: "40px",
    color: "#1B2430",
  },
  tooltipstyle: {
    fontSize: "large",
    paddingBottom: "3px",
    color: "#8D9298",
  },

  chartSubHeadingPercentNegative: {
    fontWeight: 400,
    fontSize: "12px",
    textDecoration: "none",
    lineHeight: "18px",
    color: "#FC3652",
    //  paddingTop: "15px",
  },
  chartSubHeadingPercentPositive: {
    fontWeight: 400,
    fontSize: "12px",
    textDecoration: "none",
    lineHeight: "18px",
    color: "#13CF8F",
    // paddingTop: "15px",
  },

  locationDetailBoxChart: {
    paddingTop: "0px",
    paddingLeft: "10px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  locationDetailBoxChartdetails: {
    // width: "100%",
    paddingTop: "0px",

    overflow: "hidden",
  },
  percentageBox: {
    display: "flex",
    paddingTop: "10px",
    width: "60px",
    marginLeft: "5px",
  },
  mainGridCharts: {
    display: "flex",
  },
  resultsMainGrid: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "18px",
  },
  jobLoadingButton: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    width: "auto",
    height: "48px",
    color: "white",
    borderRadius: "8px",
    marginRight: "10px",
  },
  postLoadingButton: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    width: "auto",
    height: "48px",
    color: color?.darkGrey,
    border: "1px solid #c4c4c4",
    borderRadius: "8px",
    marginRight: "10px",
  },
  postSubmitButton: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    width: "98px",
    height: "48px",
    borderRadius: "8px",
  },
  PostAiButton: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    width: "136px",
    height: "48px",
    borderRadius: "8px",
  },
  postAIBox: {
    bottom: "10px",
    // width: "100%",
    position: "absolute",
  },
  lastsyncText: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#495059",
    marginTop: "8px",
    marginX: "8px",
  },
  lastdateText: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#495059",
    marginTop: "8px",
    marginX: "8px",
    textAlign: "center",
  },
  cardcontent: {
    marginBottom: "8px",
    // bgcolor: "white",
    display: "flex",
    padding: "8px",
    cursor: "pointer",

    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  titleNameLocation: {
    //   fontFamily: "Work Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: color?.darkGrey,
  },
  subheadName: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.grey,
    textAlign: "center",
  },
  locationAddress: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: color.grey,
    // textAlign: "center",
  },
  subheadNavigateCards: {
    fontSize: "16px",
    fontWeight: 700,
    color: color.darkGrey,
  },
  loaderContainer1: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginTop: "50px",
  },
  ratingCardContainer: {
    bgcolor: "#ffff",
    borderRadius: "8px",
    // height: "100%",
    position: "relative",
  },
  cardStatsContainer: {
    bgcolor: "#ffff",
    borderRadius: "8px",
    height: "auto",
    padding: "16px",
  },
  locationLoader: {
    padding: "18px",
    marginTop: "100px",
    width: "100%",
  },
  listingGrid: {
    padding: "16px",
  },
  tagsBox: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 350,
  },
  categoryGrid: {
    padding: "16px",
    alignItems: "center",
  },
  categoriesSocialBox: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    marginBottom: "4px",
  },
  connectBoxLocation: {
    borderRadius: "8px",
    border: "1px solid #E0E0E0",
    padding: "24px",
    marginBottom: "8px",
  },
  connectBoxLocationCard: {
    borderRadius: "8px",
    border: "1px solid #E0E0E0",
    padding: "18px",
    marginBottom: "8px",
  },
  reconnectButtonLocation: {
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 14px",
    width: "114px",
    height: "48px",
    background: color?.lightBlue,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    marginRight: "10px",
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.white,
    "&:hover ": {
      background: color?.lightBlue,
      borderRadius: "8px",
      color: color?.white,
    },
  },
  UnsubscribeButtonLocation: {
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 14px",
    width: "114px",
    height: "48px",
    background: color?.darkPink,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.white,
    "&:hover ": {
      background: color?.magenta,
      borderRadius: "8px",
      color: color?.white,
    },
  },
  subscribeButtonLocation: {
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 14px",
    width: "94px",
    height: "40px",
    background: color?.lightBlue,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.white,
    "&:hover ": {
      background: color?.lightBlue,
      borderRadius: "8px",
      color: color?.white,
    },
  },
  gridContainerone: {
    height: "100%",
    display: "flex",
    // justifyContent: "space-between",
    overflow: "hidden !important",
    width: "100%",
  },
  gridContainertwo: {
    overflow: "auto",
    height: "430px",
    //  width: "100%",
    // justifyContent: "center",
    // margin: "5px",
  },
  gridContainertwoother: {
    overflow: "auto",
    height: "410px",
    //  width: "100%",
    // justifyContent: "center",
    // margin: "5px",
  },
  gridContainertwoattribute: {
    overflow: "auto",
    height: "380px",
    //  width: "100%",
    // justifyContent: "center",
    // margin: "5px",
  },
  gridContainerthree: {
    height: "100px",
    marginBottom: "2px",
  },
  listingChannelTableTxt: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#1B2430",
  },
  sourceImage: {
    width: "24px",
    height: "24px",
  },
  listingChannelTablehead: {
    fontSize: "14px",
    fontWeight: "700",
    color: "#1B2430",
    paddingBottom: "8px",
  },
  lodgingTextContainer: {
    padding: "10px 0px 0px 12px",
  },
  lodgingContainerFour: {
    padding: "10px 0px 0px 16px",
  },
  lodgingContainerFive: {
    padding: "2px 0px 0px 16px",
  },
  lodgingAttributeText: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#1B2430",
    // paddingLeft: "8px",
  },
  lodgingGridButtons: {
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "end",
    padding: "10px 10px 0px 10px",
    alignSelf: "end",
  },
  attributesModalHeader: {
    fontSize: "20px",
    color: "#1B2430",
    fontWeight: "700",
    paddingLeft: "8px",
  },
  attributesModalHeaderBulk: {
    fontSize: "20px",
    color: "#1B2430",
    fontWeight: "700",
    //  paddingLeft: "8px",
  },
  attributesItem: {
    // background: "#EBEBEC",
    // border: "1px solid #EBEBEC",
    minWidth: "101px",
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingRight: "6px",
    paddingLeft: "6px",
    margin: "0.3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "800px",
    marginLeft: "0px",
  },
  attributesItemText: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "2px",
    //  color: "#1B2430",
  },
  attributesHeadingContainer: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  attributesCategoryContainer: {
    // padding: "12px",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
  },
  attributesSubHeading: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1B2430",
    fontWeight: "700",
    //  paddingLeft: "8px",
  },
  uberAllHeadingText: {
    fontSize: "32px",
    lineHeight: "24px",
    color: "#1B2430",
    fontWeight: "700",
    paddingTop: "25px",
    paddingBottom: "25px",
    textAlign: "center",
  },
  uberAllSubHeadingText: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#495059",
    fontWeight: "400",
    paddingTop: "15px",
    paddingBottom: "25px",
    textAlign: "center",
  },
  mainContainer: {
    background: "#FFD0D0",
    padding: "6px 16px",
    borderRadius: "7px",
    marginBottom: "10px",
  },
  MapErrorContainer: {
    background: "#FFD0D0",
    padding: "6px 16px",
    borderRadius: "7px",
    marginBottom: "10px",
  },
  errorContainer: {
    background: "#FFD0D0",
    padding: "6px 16px",
    borderRadius: "7px",
    marginTop: "12px",

    marginBottom: "12px",
  },
  errorText1: {
    fontWeight: 400,
    fontSize: "0.8125rem",
    lineHeight: "1.43",
    color: "#330404",
    padding: "5px 0px",
  },
  mapErrorText: {
    fontWeight: 400,
    fontSize: "0.8125rem",
    lineHeight: "1.43",
    color: "#330404",
    padding: "5px 0px",
    marginTop: "3px",
  },
  errorIcon: {
    color: "#FF1616",
    marginTop: "5px",
  },
  addImageAvatar: {
    width: "120px",
    height: "120px",
    marginY: "20px",
    borderRadius: "8px",
    border: "1px solid #8D9298",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    paddingTop: "12px",
  },
  addImageAvatar2: {
    width: "120px",
    height: "120px",
    marginY: "20px",
    borderRadius: "8px",
    border: "1px solid #8D9298",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    paddingTop: "12px",
    marginTop: "10px",
  },
  addPhotoImage: {
    height: "79.38px",
    width: "91.67px",
    cursor: "pointer",
    color: "#8D9298",
  },
  imageAvatar: {
    width: "80px",
    height: "80px",
    marginY: "20px",
    borderRadius: "8px",
  },
  imageCrossIcon: {
    position: "absolute",
    top: 25,
    right: 53,

    cursor: "pointer",
    width: "5px",
    height: "5px",
    backgroundColor: "#FF1616",
    padding: "10px",
    zIndex: 5,
    "&:hover": {
      backgroundColor: "#FF1616",
    },
  },
  imageCrossIcon2: {
    position: "absolute",
    top: "5px",
    width: "5px",
    height: "5px",
    right: "5px",
    padding: "10px",
    backgroundColor: "#495059",
    "&:hover": {
      backgroundColor: "#495059",
    },
    cursor: "pointer",
  },
  imageCrossIcon1: {
    position: "absolute",
    top: 8,

    cursor: "pointer",
    width: "5px",
    height: "5px",
    backgroundColor: "#FF1616",
    padding: "10px",
    zIndex: 5,
    "&:hover": {
      backgroundColor: "#FF1616",
    },
  },
  modelTypoHeading: {
    fontWeight: "700",
    color: "#1B2430",
    fontSize: "14px",
    // letterSpacing: "0.5px",
    lineHeight: "20px",
    color: "#1B2430",
    marginBottom: "10px",
  },
  modelTypoText: {
    fontWeight: "400",
    color: "#1B2430",
    fontSize: "14px",
    // letterSpacing: "0.5px",
    lineHeight: "18px",
    color: "#1B2430",
  },
  modelTypoGuildlinesGrid: {
    background: "#E0F7FF",
    padding: "5px 12px 5px 12px",
  },

  formUpload: {
    height: "16rem",
    width: "28rem",
    maxWidth: "100%",
    textAlign: "center",
    position: "relative",
  },
  lableFileUpload: {
    height: " 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: "2px",
    borderRadius: "1rem",
    // borderStyle: "dashed",
    // borderColor: "#cbd5e1",
    // backgroundColor: "#f8fafc",
  },
  dragFile: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "1rem",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  PrevNextButton: {
    fontWeight: 700,
    //  color: "#1B2430",
    fontSize: "20px",
    // letterSpacing: "0.5px",
    lineHeight: "28px",
  },
  moreDescription: {
    fontSize: "14px",
    color: "#06BDFF",
    fontWeight: "700",
  },
  reviewName: {
    fontWeight: "700",
    fontSize: "14px",
    color: "#1B2430",
    marginBottom: "8px",
    marginTop: "10px",
  },
  sideContainer: {
    width: "100%",
    height: "100%",
  },
  containerCard: {
    bgcolor: "#ffff",
    borderRadius: "8px",
    height: "auto",
  },
  gridContainers: {
    padding: "16px",
  },
  boxContainer: {
    width: "100%",
    overflowY: "scroll",
    maxHeight: "430px",
  },
  boxContainer2: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  loadingGrid: {
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    height: "430px",
  },
  gridContan: {
    padding: "18px",
  },
  gridMianContainerDesc: {
    width: "100%",
    /*  overflowY: "scroll",
            backgroundColor: "transparent", */
    height: "430px",
  },
  gridContainerDesc: {
    padding: "0px 12px 12px 12px",
  },
  boxContainerDesc: {
    display: "flex",
    justifyContent: "space-between",
  },
  boxIconBtn: {
    paddingTop: "50px",
  },
  btnDescs: {
    display: "flex",
    cursor: "pointer",
  },
  iconBtn: {
    padding: "0px",
  },
  CustomFieldWidth: {
    width: "100%",
  },
  customFieldLoad: {
    padding: "18px",
    paddingTop: "50px",
    width: "550px",
  },
  customFieldSlug: {
    padding: "10px 18px 18px 18px",
  },
  customFieldMain: {
    height: "430px",
  },
  customFieldContain: {
    marginTop: "12px",
  },
  customFieldContaines: {
    padding: "0px 18px 5px 18px",
    marginBottom: "8px",
  },
  customFieldContaines2: {
    padding: "0px 18px 5px 18px",
    marginBottom: "8px",
  },
  coverImagesBox: {
    padding: "0px 18px 0px 18px",
  },
  boxBtnCoverImg: {
    marginLeft: "5px",
    marginTop: "10px",
  },
  GridBox: {
    padding: "0px 18px 18px 18px",
  },
  gridImgContainer: {
    position: "relative",
  },
  imgShowIconBtn: {
    position: "absolute",
    top: 25,
    right: 53,
    cursor: "pointer",
    width: "5px",
    height: "5px",
    backgroundColor: "#FF1616",
    padding: "10px",
    zIndex: 5,
  },
  imgAvatar: {
    width: "150px",
    height: "150px",
    marginY: "20px",
    borderRadius: "8px",
  },
  imgIconsBtn: {
    position: "absolute",
    top: 25,
    right: 53,
    cursor: "pointer",
    width: "5px",
    height: "5px",
    backgroundColor: "#FF1616",
    padding: "10px",
    zIndex: 5,
  },
  closeBtn: {
    color: "#FFFF",
  },
  mainGridIndex: {
    padding: "0px 16px 24px 16px",
  },
  gridBoxMain: {
    marginTop: "10px",
  },
  attributBox: {
    display: "flex",
    paddingTop: "10px",
  },
  attributAlign: {
    textAlign: "end",
  },
  attributeStyle: {
    display: "flex",
  },
  attributsCustoms: {
    paddingTop: "8px",
  },
  redCheckbox: {
    color: "red", // Change this to your desired color
    "&$checked": {
      color: "red", // Change this to your desired color for the checked state
    },
  },
  customTableContainer: {
    height: "100%",
    "& .itrmac .MuiPaper-root .MuiPaper-root": {
      overflow: "hidden",
    },
  },
  openingHoursStatus: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B2430",
    paddingTop: "2px",
    textAlign: "center",
    margin: "12px",
  },
  openingHoursStatusTemp: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B2430",
    paddingTop: "2px",
    textAlign: "center",
    margin: "12px",
    color: "#E3963E",
  },
  openingHoursPerm: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B2430",
    paddingTop: "2px",
    textAlign: "center",
    margin: "12px",
    color: "#FF1616",
  },
  radioLabel: {
    fontWeight: "700",
    fontSize: "14px",
    color: "#1B2430",
    paddingTop: "2px",
  },
  radioLabeSubheading: {
    fontWeight: "400",
    fontSize: "14px",
    paddingTop: "5px",
    color: "#8D9298",
  },
  radioContainer: {
    marginBottom: "10px",
  },
  timePickerRoot: {
    color: "#1B2430",
    backgroundColor: "#f5f5f5",

    "& .MuiPickersLayout-root-fgPIAm ": {
      backgroundColor: "#f5f5f5",
    },
    "& .MuiPickersLayout-root": {
      backgroundColor: " #f5f5f5",
    },
    "& .MuiList-root": {
      width: "75px",
      textAlign: "-webkit-center",
    },

    "& .MuiList-root-fLOhBn": {
      width: "75px",
      textAlign: "-webkit-center",
    },
    "& .MuiDialogActions-root": {
      display: "none",
    },
  },
  socialAccountsImage: {
    width: "20px",
    height: "20px",
  },
  socialAccountsFieldGrid: {
    marginBottom: "15px",
  },
  socialImageLabel: {
    marginLeft: "10px",
    fontWeight: "bold",
    marginTop: "-3px",
  },
  socialImageBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
  },
  coloredClass: {
    color: "#06BDFF",
  },
  coloredClassTwo: {
    color: "#06BDFF",
    marginTop: "20px",
  },
  subContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "7px",
  },
  flexClass: {
    display: "flex",
    alignItems: "center",
  },
  urlClass: {
    fontSize: "11px",
    color: "#495059",
  },
  subContainerDiv: {
    marginLeft: "8px",
    width: "100%",
  },
  actionsClass: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "15px",
  },
  badgeClass: {
    width: "150px",
    height: "150px",
    color: "rgb(6, 189, 255)",
  },
  profileSuggestText: {
    fontSize: "18px",
    fontWeight: 700,
  },
  improveProfileText: {
    fontSize: "18px",
    fontWeight: 400,
    marginTop: "5px",
  },
  actionsContainer: {
    marginTop: "15px",
    marginLeft: "10px",
  },
  iconClass: {
    cursor: "pointer",
  },
  mainContainerUberAllForCard: {
    background: "#FFD0D0",
    padding: "6px 6px",
    borderRadius: "7px",
    width: "350px",
    // marginBottom: "10px",
    //  marginTop: "10px",
  },
  errorIconUberAll: {
    color: "#FF1616",
    //  marginTop: "5px",
    width: "15px",
  },
  errorTextUberAll: {
    color: "#FF1616",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "1.43",
    padding: "6px 0px",
    // marginTop: "5px",
  },
  closeIconUberAll: {
    color: "#FF1616",
    cursor: "pointer",
    // marginTop: "3px",
    width: "15px",
    paddingRight: "3px",
  },
  addButton: {
    color: "#06BDFF",
    fontWeight: 700,
    fontSize: "14px",
    marginLeft: "3px",
  },

  addButtonDisabled: {
    color: color?.lightGrey,
    fontWeight: 700,
    fontSize: "14px",
    marginLeft: "3px",
  },
  addIconDisabled: {
    color: color?.lightGrey,
    marginLeft: "3px",
  },
  addIcon: {
    color: "#06BDFF",

    marginLeft: "3px",
  },
  moreHoursText: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "2px",
    color: "#1B2430",
  },
  servicesTypoDesc: {
    fontSize: "12px",
    paddingX: "4px",
    fontWeight: "400",
    marginX: "4px",
    color: "#555555",
  },
  locationAddressCard: {
    background: "#F1F7FF",
    borderRadius: "8px",
    height: "auto",
    padding: "16px",
  },
  locationAddressCardRequested: {
    background: "#FFF7E7",
    borderRadius: "8px",
    height: "auto",
    padding: "16px",
  },
  locationAddressCardLabels: {
    fontSize: "16px",
    fontWeight: "700",
    color: "#1B2430",
  },
  editIconAddress: {
    color: "#06BDFF",
    cursor: "pointer",
  },
  nameLabel: {
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: "700",
    color: "rgba(0,0,0,0.87)",
  },
  tickAddressIcon: {
    color: "#06BDFF",
    cursor: "pointer",
  },
  tickAddressIconRequested: {
    color: "#13CF8F",
    cursor: "pointer",
  },
  locationPermanentClosedText: {
    //styleName: Label/Regular/rg;

    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#FF1616",
  },
  toggleText: {
    marginRight: 1,
    marginLeft: "8px",
    color: "#1B2430",
    fontSize: "16px",
    lineHeight: "24px",
  },
  loaderBoxBulk: {
    padding: "18px",
    //  paddingTop: "150px",
    width: "100%",
  },
  gridContainertwoBulk: {
    overflow: "auto",
    height: "460px",
    //  width: "100%",
    // justifyContent: "center",
    // margin: "5px",
  },
  toast: {
    width: "100%",
    display: "flex",
    paddingTop: "0 1.25rem",
    paddingBottom: "1rem",
  },
  toastBox: {
    backgroundColor: "#E8F2FF",
    width: "100%",
    display: "flex",
    borderRadius: "4px",
    //   gap: "1rem",
  },
  toastBoxBorder: {
    backgroundColor: "#06BDFF",
    width: "6px",
    borderRadius: "4px 0 0 4px",
  },
  toastBoxContent: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0px 8px",
  },
  toastBoxText: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0.5rem 0",
    gap: "1rem",
  },
  loaderBoxSpecialHours: {
    padding: "18px",
    // paddingTop: "150px",
    width: "100%",
  },
}));

// .tags-input {
//   flex-grow: 1;
//   padding: 0.5em 0;
//   border: none;
//   outline: none;
// }
