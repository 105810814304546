import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
  Checkbox,
  DialogContent,
  Dialog,
  CircularProgress,
  createFilterOptions,
} from "@mui/material";
import { api } from "../../../contexts/JWTContext";
import SearchField from "../../CustomComponents/textfields/searchfield/SearchField";
import FetchModels from "./FetchModels.css";
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const useStyles = makeStyles((theme) => ({
  tooltipstyle: {
    fontSize: "large",
    paddingBottom: "3px",
    color: "#8D9298",
  },
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "800px",
    },
    "& .MuiFilledInput-root": {
      background: "white",
      borderRadius: "800px",
    },
    input: {
      color: "white",
      // height: "48px",
      borderRadius: "800px",
    },
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  height: "30px",
  padding: "6px",
  paddingRight: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: "800px",
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.typography.body2,
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const FetchModel = ({
  title,
  open,
  handleCloseNewRequestModel,
  allLocations,
  selectedLocation,
  setSelectedLocation,
  getsentimentalLocation,
  requestLoading,
  userData,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [deletePost, setDeletePost] = useState(null);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [selectAll, setSelectAll] = useState(false);
  const filter = createFilterOptions();
  const [filterWithName, setFilterWithName] = useState("");
  const [allLocationsModel, setAllLocationsModel] = useState(allLocations);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) {
        getAllLocation();
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filterWithName]);

  const getAllLocation = async () => {
    setSearch(false);
    setIsLoading(true);

    const filteredData = allLocations?.filter(
      (item) =>
        item?.name.toLowerCase().includes(filterWithName.toLowerCase()) ||
        item?.internalName.toLowerCase().includes(filterWithName.toLowerCase())
    );
    if (filteredData?.length > 0) {
      setAllLocationsModel(filteredData);

      //  setFilterWithName("");
    } else {
      setAllLocationsModel([]);
    }

    setIsLoading(false);
  };

  const filterByName = (e) => {
    setFilterWithName(e.target.value);
    setSearch(true);
  };
  return (
    <>
      <div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "770px",
              height: "500px",
              borderRadius: "4px",
            },
          }}
          fullScreen
          open={open}
          onClose={handleCloseNewRequestModel}
        >
          <DialogTitle
            sx={{
              lineHeight: "28px",
              fontWeight: 700,
              fontSize: "20px",
              color: "#1b2430",
            }}
          >
            {title}
            <BootstrapTooltip
              title={
                <h1 style={{ fontSize: "12px" }}>
                  {t("Text_tooltipofemotion")}
                </h1>
              }
            >
              <IconButton>
                <InfoIcon className={classes.tooltipstyle} />
              </IconButton>
            </BootstrapTooltip>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container>
              <Grid item sm={7} md={7} lg={7}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    color: "#1B2430",
                  }}
                >
                  {t("Select_Location")}
                </Typography>

                {/*    <Autocomplete
                  multiple
                  disablePortal
                  disableCloseOnSelect
                  id="combo-box-demo"
                  options={allLocations?.length > 0 ? allLocations : []}
                  //  sx={{ height: "30vh" }}

                  getOptionDisabled={(option) => {
                    if (
                      option?.reviewFoundStatus===false &&
                      option?.statusOfSentimentalAnalysis==="pending"
                    ) {
                      return true;
                    }
                    if (option?.statusOfSentimentalAnalysis==="requested") {
                      return true;
                    }
                    if (option?.statusOfSentimentalAnalysis==="done") {
                      return true;
                    }
                    if (option?.statusOfSentimentalAnalysis==="inProgress") {
                      return true;
                    }
                    return false;
                  }}
                  getOptionLabel={(option) =>
                    option?.internalName
                      ? option?.internalName +
                        " " +
                        "(" +
                        (option?.addressLine1 !== "" &&
                        option?.addressLine1 !== null &&
                        option?.addressLine1 !== undefined
                          ? option?.addressLine1 + "," + " "
                          : "") +
                        (option?.city ? option?.city : "") +
                        ")"
                      : ""
                  }
                  className="Autocomplete-field"
                  // value={locationId}
                  value={selectedLocation?.length ? selectedLocation : []}
                  //   onChange={(e, value) => {
                  //   setSelectedLocation(value);
                  //   //  setLocationRequired(false);
                  // }}
                  onChange={(event, value) => {
                    // Check if 'All' option is clicked
                    const filterLocations = allLocations?.filter(
                      (item) =>
                        item?.reviewFoundStatus !== false &&
                        item?.statusOfSentimentalAnalysis !== "done" &&
                        item?.statusOfSentimentalAnalysis !== "requested" &&
                        item?.statusOfSentimentalAnalysis !== "inProgress"
                    );
                    if (value.find((option) => option.id === 0)) {
                      // Check if all options are selected

                      if (
                        filterLocations?.length === selectedLocation?.length
                      ) {
                        setSelectedLocation([]);
                        setSelectAll(false);
                      } else {
                        setSelectAll(true);
                        setSelectedLocation(filterLocations);
                      }
                    } else {
                      setSelectedLocation(value);
                      if (value?.length === filterLocations?.length) {
                        setSelectAll(true);
                      } else {
                        setSelectAll(false);
                      }
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    return [{ id: 0, internalName: "Select All" }, ...filtered];
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        disabled={
                          (option?.reviewFoundStatus===false &&
                            option?.statusOfSentimentalAnalysis==="pending") ||
                          option?.statusOfSentimentalAnalysis==="requested" ||
                          option?.statusOfSentimentalAnalysis==="done" ||
                          option?.statusOfSentimentalAnalysis==="inProgress"
                        }
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        //  checked={selected }
                        checked={
                          option?.reviewFoundStatus !== false &&
                          option?.statusOfSentimentalAnalysis !== "requested" &&
                          option?.statusOfSentimentalAnalysis !== "done" &&
                          option?.statusOfSentimentalAnalysis !==
                            "inProgress" &&
                          (selectAll || selected)
                        }
                      />
                      <Grid
                        container
                        style={{ justifyContent: "space-between" }}
                      >
                        <Grid item xs={9} sm={9} md={9} lg={9}>
                          <span style={{ textAlign: "start" }}>
                            {`${option?.internalName} ${
                              option?.addressLine1 !== "" &&
                              option?.addressLine1 !== null &&
                              option?.addressLine1 !== undefined
                                ? "(" + option?.addressLine1 + "," + " "
                                : ""
                            } ${option?.city ? option?.city + ")" : ""}`}
                          </span>
                        </Grid>

                        {option?.statusOfSentimentalAnalysis && (
                          <Grid item={3} sm={3} md={3} lg={3}>
                            <span
                              style={{
                                textAlign: "center",
                                // marginLeft: "8px",
                                // marginRight: "18px",
                                color:
                                  option?.statusOfSentimentalAnalysis === "done"
                                    ? "#13CF8F"
                                    : option?.reviewFoundStatus===false
                                    ? "#FC3652"
                                    : option?.statusOfSentimentalAnalysis ===
                                      "requested"
                                    ? "#F86F35"
                                    : option?.statusOfSentimentalAnalysis ===
                                      "inProgress"
                                    ? "#FC3652"
                                    : option?.reviewFoundStatus === true &&
                                      option?.statusOfSentimentalAnalysis ===
                                        "pending"
                                    ? "#F8A92B"
                                    : "",
                              }}
                            >
                              {option?.statusOfSentimentalAnalysis==="done"
                                ? "• " + `${t("Done")}`
                                : ""}
                              {option?.reviewFoundStatus===false
                                ? "• " + `${t("No_reviews")}`
                                : ""}
                              {option?.statusOfSentimentalAnalysis ==
                              "requested"
                                ? "• " + `${t("requested_state")}`
                                : ""}
                              {option?.statusOfSentimentalAnalysis ==
                              "inProgress"
                                ? "• " + `${t("inProgress")}`
                                : ""}
                              {option?.reviewFoundStatus===true &&
                              option?.statusOfSentimentalAnalysis==="pending"
                                ? "• " + `${t("Pending")}`
                                : ""}
                            </span>
                          </Grid>
                        )}
                      </Grid>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("SelectLocation")}
                      {...params}
                      required
                      //  error={locationRequired}
                      name="language"
                    />
                  )}
                ></Autocomplete> */}
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                {/* <SearchField
                  filterByName={filterByName}
                  onSubmit={getAllLocation}
                  placeholder={t("filter_location_by_name")}
                  filterWithName={filterWithName}
                /> */}

                <TextField
                  placeholder={t("filter_location_by_name")}
                  sx={{ borderRadius: "800px" }}
                  fullWidth
                  className={classes.root}
                  autoComplete="off"
                  //  id="outlined-name"
                  value={filterWithName}
                  onSubmit={getAllLocation}
                  size="small"
                  InputProps={{
                    className: classes.input,

                    startAdornment: (
                      <IconButton>
                        <SearchOutlinedIcon />
                      </IconButton>
                    ),
                  }}
                  onKeyPress={(event) => {
                    // setError(false);
                    if (event.key === "Enter") {
                      getAllLocation();
                    }
                  }}
                  onChange={filterByName}
                />
              </Grid>
              <Grid
                container
                sx={{
                  gap: "10px",
                  marginTop: "12px",
                  padding: "8px",
                  border: "1px solid #EBEBEC",
                  borderRadius: "8px",
                }}
              >
                {allLocationsModel?.length > 0 &&
                  allLocationsModel?.map((itemTwo) => (
                    <Item
                      onClick={() => ""}
                      style={{
                        padding: "12px 8px 12px 8px",
                        gap: "4px",
                        border:
                          (itemTwo?.reviewFoundStatus === false &&
                            itemTwo?.statusOfSentimentalAnalysis ==
                              "pending") ||
                          itemTwo?.statusOfSentimentalAnalysis ===
                            "requested" ||
                          itemTwo?.statusOfSentimentalAnalysis === "done" ||
                          itemTwo?.statusOfSentimentalAnalysis === "inProgress"
                            ? "none"
                            : selectedLocation?.find(
                                (item) => item?.id === itemTwo?.id
                              )
                            ? "1px solid #06BDFF"
                            : "1px solid #BBBDC1",
                        borderRadius: "800px",
                        background:
                          (itemTwo?.reviewFoundStatus === false &&
                            itemTwo?.statusOfSentimentalAnalysis ==
                              "pending") ||
                          itemTwo?.statusOfSentimentalAnalysis ===
                            "requested" ||
                          itemTwo?.statusOfSentimentalAnalysis === "done" ||
                          itemTwo?.statusOfSentimentalAnalysis === "inProgress"
                            ? "#F0F0F0"
                            : selectedLocation?.find(
                                (item) => item?.id === itemTwo?.id
                              )
                            ? "#FFF"
                            : "#FFF",

                        //  height: "45px",
                      }}
                    >
                      <Checkbox
                        checked={
                          selectedLocation?.find(
                            (loc) => loc?.id === itemTwo?.id
                          ) !== undefined
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          let arr = [...selectedLocation];
                          if (e.target.checked) {
                            arr.push(itemTwo);
                            setSelectedLocation(arr);
                          } else {
                            const filtered = arr.filter(
                              (loc) => loc?.id !== itemTwo?.id
                            );
                            setSelectedLocation(filtered);
                          }
                        }}
                        disabled={
                          (itemTwo?.reviewFoundStatus === false &&
                            itemTwo?.statusOfSentimentalAnalysis ==
                              "pending") ||
                          itemTwo?.statusOfSentimentalAnalysis ===
                            "requested" ||
                          itemTwo?.statusOfSentimentalAnalysis === "done" ||
                          itemTwo?.statusOfSentimentalAnalysis === "inProgress"
                        }
                        size="small"
                        sx={{
                          paddingLeft: "2px",
                          paddingRight: "2px",
                        }}
                      />

                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "18px",
                          color:
                            (itemTwo?.reviewFoundStatus === false &&
                              itemTwo?.statusOfSentimentalAnalysis ==
                                "pending") ||
                            itemTwo?.statusOfSentimentalAnalysis ==
                              "requested" ||
                            itemTwo?.statusOfSentimentalAnalysis === "done" ||
                            itemTwo?.statusOfSentimentalAnalysis ===
                              "inProgress"
                              ? "gray !important"
                              : selectedLocation?.find(
                                  (item) => item?.id === itemTwo?.id
                                )
                              ? "#1B2430"
                              : "#1B2430",
                        }}
                      >
                        {itemTwo.internalName +
                          (itemTwo?.city !== null &&
                            itemTwo?.city !== "" &&
                            " " + "(" + itemTwo?.city + ")")}
                      </Typography>
                      <span
                        style={{
                          textAlign: "center",
                          // marginLeft: "8px",
                          // marginRight: "18px",
                          color:
                            itemTwo?.statusOfSentimentalAnalysis === "done"
                              ? "#13CF8F"
                              : itemTwo?.reviewFoundStatus === false
                              ? "#FC3652"
                              : itemTwo?.statusOfSentimentalAnalysis ===
                                "requested"
                              ? "#F86F35"
                              : itemTwo?.statusOfSentimentalAnalysis ===
                                "inProgress"
                              ? "#FC3652"
                              : itemTwo?.reviewFoundStatus === true &&
                                itemTwo?.statusOfSentimentalAnalysis ===
                                  "pending"
                              ? "#F8A92B"
                              : "",
                        }}
                      >
                        {itemTwo?.statusOfSentimentalAnalysis === "done"
                          ? "• " + `${t("Done")}`
                          : ""}
                        {itemTwo?.reviewFoundStatus === false
                          ? "• " + `${t("No_reviews")}`
                          : ""}
                        {itemTwo?.statusOfSentimentalAnalysis === "requested"
                          ? "• " + `${t("requested_state")}`
                          : ""}
                        {itemTwo?.statusOfSentimentalAnalysis === "inProgress"
                          ? "• " + `${t("inProgress")}`
                          : ""}
                        {itemTwo?.reviewFoundStatus === true &&
                        itemTwo?.statusOfSentimentalAnalysis === "pending"
                          ? "• " + `${t("Pending")}`
                          : ""}
                      </span>
                    </Item>
                  ))}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                handleCloseNewRequestModel();
                setSelectedLocation([]);
                setFilterWithName("");
                setSelectAll(false);
              }}
              className="delete_button"
              sx={{ color: "#1B2430" }}
            >
              {t("Cancel")}
            </Button>

            <Button
              autoFocus
              disabled={selectedLocation?.length === 0 ? true : false}
              onClick={() => getsentimentalLocation()}
              className="delete_button"
            >
              {requestLoading ? (
                <CircularProgress
                  color="secondary"
                  style={{ width: "25px", height: "25px", color: "#06BDFF" }}
                />
              ) : (
                t("Submit")
              )}
            </Button>
          </DialogActions>
        </Dialog>

        {/* </DialogActions> */}
      </div>
    </>
  );
};

export default FetchModel;
