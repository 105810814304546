import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import GoogleMapInpuField from "../../../components/GoogleMapField";
// import Loader from "../../components/Loaders/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import tick from "../../../assets/images/tick.jpg";

import { api } from "../../../contexts/JWTContext";
import { useTranslation } from "react-i18next";
import { useStyles } from "../styles/styles";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  Typography,
  Grid,
  Box,
  Divider,
  DialogContent,
  Dialog,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../../hooks/useAuth";
import { LoadingButton } from "@mui/lab";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function Register({
  isFullWidth,
  isDisabledEmail,
  openSuccessModel,
  setOpenSuccessModel,
  setTokenAlreadyUsedError,
  setTokenExpiredError,
  setInvalidTokenError,
  setEmailAlreadyUsedError,
  token,
  userInfo,
  setUserInfo,
  emailDisabled,
}) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [placeId, setPlaceId] = useState("");
  const { signUp } = useAuth();
  const [firstNameRequired, setFirstNameRequired] = useState(false);
  // const [lastNameRequired, setLastNameRequired] = useState(false);
  const [emailRequired, setEmailRequired] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [confirmPasswordRequired, setConfirmPasswordRequired] = useState(false);
  const [samePassword, setSamePassword] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userRegisterLoading, setUserRegisterLoading] = useState(false);

  const checkSubmit = async () => {
    if (userInfo?.firstName === "") {
      setFirstNameRequired(true);
    }
    // if (userInfo?.lastName==="") {
    //   setLastNameRequired(true);
    // }
    if (userInfo?.email === "") {
      setEmailRequired(true);
    }
    if (userInfo?.password === "") {
      setPasswordRequired(true);
    }
    if (userInfo?.confirmPassword === "") {
      setConfirmPasswordRequired(true);
    }

    if (userInfo?.confirmPassword !== userInfo?.password) {
      setSamePassword(true);
    }
    // setOpenSuccessModel(true);
    if (
      userInfo?.firstName !== "" &&
      userInfo?.email !== "" &&
      userInfo?.password !== "" &&
      userInfo?.lastName !== "" &&
      userInfo?.confirmPassword !== "" &&
      userInfo?.password === userInfo?.confirmPassword
    ) {
    }
  };

  const handleUserRegister = async () => {
    if (userInfo?.firstName === "") {
      setFirstNameRequired(true);
    }
    // if (userInfo?.lastName==="") {
    //   setLastNameRequired(true);
    // }
    if (userInfo?.email === "") {
      setEmailRequired(true);
    }
    if (userInfo?.password === "") {
      setPasswordRequired(true);
    }
    if (userInfo?.confirmPassword === "") {
      setConfirmPasswordRequired(true);
    }

    if (userInfo?.confirmPassword !== userInfo?.password) {
      setSamePassword(true);
    }

    if (
      userInfo?.firstName !== "" &&
      userInfo?.email !== "" &&
      userInfo?.password !== "" &&
      // userInfo?.lastName !== "" &&
      userInfo?.confirmPassword !== "" &&
      userInfo?.password === userInfo?.confirmPassword
    ) {
      try {
        setUserRegisterLoading(true);
        const res = await api.post(`user/register`, {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          email: userInfo.email,
          password: userInfo.password,
          token,
        });
        if (res.status === 201) {
          setUserRegisterLoading(false);
          toast.success(res?.data?.message);
          setOpenSuccessModel(true);
        }
      } catch (error) {
        setUserRegisterLoading(false);

        if (
          error?.response?.data?.message === "Token is invalid or used already"
        ) {
          setTokenAlreadyUsedError(true);
        }

        if (error?.response?.data?.message === "Token has expired") {
          setTokenExpiredError(true);
        }

        if (error?.response?.data?.message === "invalid token") {
          setInvalidTokenError(true);
        }
        if (error?.response?.data?.message === "This email already exists") {
          // toast.error(error?.response?.data?.message);
          setEmailAlreadyUsedError(true);
        }
      }
    }
  };

  return (
    <>
      {openSuccessModel && (
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "80%",
              height: "auto",
              padding: "20px",
              borderRadius: "8px",
            },
          }}
          open={openSuccessModel}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogContent>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "center" }}
              >
                <img alt="" src={tick} width="80px" height="80px" />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "48px",
                    lineHeight: "60px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {t("Thankss")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px", display: "flex" }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "28px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {t("register_success_message")}
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "20px",
                      lineHeight: "28px",
                      textAlign: "center",
                      //  color: "#1B2430",
                    }}
                    // className={classes.signupLabel}
                    // disabled={loading}
                    component={Link}
                    to="/auth/sign-in"
                    variant="contained"
                    fullWidth
                    color="primary"
                  >
                    {t("Click_Here")}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      <Grid container>
        {message.length !== 0 && (
          <Alert mt={2} mb={1} severity={isSuccess ? "success" : "error"}>
            {message}
          </Alert>
        )}

        {/* <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          /> */}
        <Typography className={classes.nameLabel}>{t("First_Name")}</Typography>
        <TextField
          // disabled={loading}
          disabled={loading}
          type="text"
          name="firstName"
          placeholder={t("First_Name")}
          value={userInfo?.firstName}
          error={firstNameRequired}
          fullWidth
          helperText={firstNameRequired && t("First_name_req")}
          onChange={(e) => {
            setUserInfo({
              ...userInfo,
              firstName: e.target.value,
            });
            if (e.target.value?.length > 0) {
              setFirstNameRequired(false);
            } else {
              setFirstNameRequired(true);
            }
          }}
        />
        <Typography className={classes.nameLabel}>{t("Last_Name")}</Typography>
        <TextField
          // disabled={loading}
          disabled={loading}
          autoComplete="off"
          type="text"
          name="lastName"
          placeholder={t("Last_Name")}
          value={userInfo?.lastName}
          fullWidth
          // error={lastNameRequired}
          // helperText={lastNameRequired && t("Last_name_req")}
          onChange={(e) => {
            setUserInfo({
              ...userInfo,
              lastName: e.target.value,
            });
            // if (e.target.value?.length > 0) {
            //   setLastNameRequired(false);
            // } else {
            //   setLastNameRequired(true);
            // }
          }}
        />

        <Typography className={classes.nameLabel}>
          {t("Email_Address")}
        </Typography>
        <TextField
          disabled={loading ? true : emailDisabled ? true : false}
          type="email"
          name="email"
          placeholder="Email address"
          defaultValue=""
          autoComplete="none"
          fullWidth
          value={userInfo?.email}
          error={emailRequired || validEmail}
          helperText={
            emailRequired
              ? t("Email_required_text")
              : validEmail
              ? t("valid_emaill")
              : ""
          }
          onChange={(e) => {
            setUserInfo({
              ...userInfo,
              email: e.target.value,
            });
            if (e.target.value?.length > 0) {
              setEmailRequired(false);
              if (e.target.value.length > 0) {
                if (
                  /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/.test(
                    e.target.value
                  )
                ) {
                  setValidEmail(false);
                } else {
                  setValidEmail(true);
                }
              } else {
                setValidEmail(false);
              }
            } else {
              setEmailRequired(true);
              setValidEmail(false);
            }
          }}
        />

        <Typography className={classes.nameLabel}>{t("Password")}</Typography>
        <TextField
          // disabled={loading}
          disabled={loading}
          type="password"
          name="password"
          placeholder={t("Password")}
          defaultValue=""
          autoComplete="none"
          // inputProps={{
          //   autoComplete: "none",
          // }}
          value={userInfo?.password}
          error={passwordRequired || passwordLength}
          fullWidth
          helperText={
            passwordRequired
              ? t("Password_required_text")
              : passwordLength
              ? t("valid_pass")
              : ""
          }
          onChange={(e) => {
            setUserInfo({
              ...userInfo,
              password: e.target.value,
            });
            if (e.target.value?.length > 0) {
              if (e.target.value?.length < 5) {
                setPasswordLength(true);
              } else {
                setPasswordLength(false);
              }
              if (e.target.value?.length > 0) {
                if (
                  userInfo?.confirmPassword?.length > 0 &&
                  userInfo?.confirmPassword !== e.target.value
                ) {
                  setSamePassword(true);
                } else {
                  setSamePassword(false);
                }
              } else {
                setPasswordLength(false);
                setSamePassword(false);
              }
              setPasswordRequired(false);
            } else {
              setPasswordRequired(true);
            }
          }}
        />
        <Typography className={classes.nameLabel}>
          {t("Confirm_Password")}
        </Typography>
        <TextField
          // disabled={loading}
          disabled={loading}
          type="password"
          name="confirmPassword"
          placeholder={t("Confirm_Password")}
          value={userInfo?.confirmPassword}
          fullWidth
          error={confirmPasswordRequired || samePassword}
          helperText={
            confirmPasswordRequired
              ? t("Password_required_text")
              : samePassword
              ? t("Both_password")
              : ""
          }
          onChange={(e) => {
            setUserInfo({
              ...userInfo,
              confirmPassword: e.target.value,
            });
            if (
              e.target.value?.length > 0 &&
              e.target.value !== userInfo?.password
            ) {
              setSamePassword(true);
              setConfirmPasswordRequired(false);
            } else {
              setSamePassword(false);
            }
            if (e.target.value?.length > 0) {
              setConfirmPasswordRequired(false);
            } else {
              setConfirmPasswordRequired(true);
            }
          }}
        />

        {!isDisabledEmail && (
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            loading={userRegisterLoading}
            disabled={
              userRegisterLoading ||
              firstNameRequired ||
              // lastNameRequired ||
              emailRequired ||
              validEmail ||
              samePassword ||
              passwordLength ||
              passwordLength
            }
            onClick={handleUserRegister}
            className={classes.signupButton}
          >
            {t("reister_label")}
          </LoadingButton>
        )}
        {/* <Button
            disabled={loading}
            component={Link}
            to="/auth/sign-in"
            variant="contained"
            fullWidth
            color="primary"
          >
            Sign-In
          </Button> */}
        {/*   <Typography
        className={classes.signupLabel}
        disabled={loading}
        component={Link}
        to="/auth/sign-in"
        variant="contained"
        fullWidth
        color="primary"
      >
        {t("Sign_up_text")}
      </Typography> */}
      </Grid>
    </>
  );
}

export default Register;
